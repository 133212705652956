//.shape-container {
//  --shape-text-line-height: 0.6;
//  line-height: var(--shape-text-line-height);
//}

.text-shape {
  --shape-text-line-height: 0.6;
  position: relative;
  display: inline-block;
  //min-height: 1em;
  vertical-align: bottom;

  &__label {
    position: relative;
    z-index: 2;
  }
  &__shape {
    &.is--mirrored {
      scale: -1 -1;
    }
  }
  &.has--text {
    .text-shape__shape {
      @include abs(50%, 0, 0, 0);
      width: 100%;
      height: calc(var(--shape-text-line-height, 0.2) * 1em + 1em);
      translate: 0% -40%;
    }

    // specific overrides per shape
    // &.is--squiggle-long .text-shape__shape {
    //   transform: translate(0%, -20%);
    //   left: -10%;
    // }
  }
}

// wiggle test
@keyframes wiggleShape {
  0% {
    transform: rotate(1deg) skew(1deg, 1deg);
  }
  50% {
    transform: rotate(2deg) skew(4deg, 3deg);
  }
  100% {
    transform: rotate(3deg) skew(-3deg, 3deg);
  }
}

// div is injected by lottie
//ng-lottie.type--textbg > div {
//  transform: rotate(2deg) skew(2deg, 1deg);
//  animation: wiggleShape 1.2s steps(2, jump-both) infinite;

//  animation-delay: calc(var(--splitting-duration) + (var(--text-shape-delay) * 1ms));
//}

@each $name, $color in map-merge($theme-colors, $colors) {
  .#{'fill-' + $name} path {
    fill: var(--bs-#{$name}) !important;
  }
}
