.icon-link {
  transition: 0.3s ease-out;
  position: absolute;
  fstr-icon {
    position: relative;
  }
  &:hover {
    svg {
      color: var(--bs-primary-600);
      fill: var(--bs-primary-600);
    }
  }
  // todo styling hover effects, test scalable icons + white space calc
  // &::before {
  //   content: '';
  //   @include size(130px);
  //   // background-color: var(--bs-primary);
  //   background-color: rgba(var(--bs-primary) 0.5);
  //   position: absolute;
  //   top: 50%;
  //   left: 50%;
  //   transform: translate(-50%, -50%);
  //   border-radius: 100%;
  // }
}
