.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.text-decoration-none {
  &:hover,
  &:focus {
    text-decoration: none;
  }
}

.text-primary-gradient {
  background: $primary-gradient;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block; // keep words together if it needs to go to a new line

  line-height: 0.9;
  padding: 0.1em;
  margin: -0.1em;

  .char {
    background: $primary-gradient;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.link-unset,
.block-link {
  text-decoration: none;
  color: currentColor;

  &:focus-visible {
    outline: 2px solid var(--bs-warning);
    outline-offset: 3px;
  }
}

.fw-light {
  font-variation-settings: 'wght' 300;
}

.fw-normal {
  font-variation-settings: 'wght' 500;
}

.fw-bold {
  font-variation-settings: 'wght' 600;
}

.fw-bolder {
  font-variation-settings: 'wght' 700;
}

.font-display {
  font-family: $display-font-family;
  font-style: italic;
  font-weight: normal;
  font-variation-settings: 'wght' 600;

  &.fw-bold {
    // font-weight: normal !important;
    font-family: $font-family-sans-serif;
    font-weight: 700;
    font-variation-settings: 'wght' 700;
  }

  &.text-primary-gradient {
    line-height: 0.8;
    font-family: $font-family-sans-serif;
    font-weight: 700;
    font-variation-settings: 'wght' 700;
  }
}

.text-wrap-pretty {
  text-wrap: pretty !important;
}

.lh-display-1 {
  line-height: 92.308%;
}
.lh-display-2 {
  line-height: 90.909%;
}
.lh-09 {
  line-height: 92% !important;
}
