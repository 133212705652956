/**
 * Section class for inner padding, outer margin or negative margin.
 * The negative option is used to pull a section over another to create overlapping content.
 */

[class^='section-'] {
  position: relative;
}

[class^='section-negative-'] {
  z-index: 1;
}

@mixin section-query($max-width) {
  @media (min-width: $max-width) {
    @content;
  }
}

@each $type, $prop in (inset: padding, outset: margin, negative: margin) {
  $class: 'section-#{$type}';
  $max: 150;
  $max-width: $max * 10px;
  $fluid: $max * 0.1vw;

  // Use negative values for pull
  @if $type == negative {
    $fluid: -$fluid;
    $max: -$max;
  }

  .#{$class}-y {
    --section-inset-value: #{$fluid};
    #{$prop}-top: var(--section-inset-value);
    #{$prop}-bottom: var(--section-inset-value);

    @include section-query($max-width) {
      --section-inset-value: #{rem($max)};
    }
  }

  .#{$class}-t {
    --section-inset-value: #{$fluid};
    #{$prop}-top: var(--section-inset-value);

    @include section-query($max-width) {
      --section-inset-value: #{rem($max)};
    }
  }

  .#{$class}-b {
    --section-inset-value: #{$fluid};
    #{$prop}-bottom: var(--section-inset-value);

    @include section-query($max-width) {
      --section-inset-value: #{rem($max)};
    }
  }
}
