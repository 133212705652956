.main-nav--active {
  position: absolute;
  overflow: hidden;
  max-height: 100vh;
  width: 100vw;
}

body {
  --topbar-height: 70px;
}

.pt-topbar {
  padding-top: var(--topbar-height);
}
.mt-topbar {
  padding-top: var(--topbar-height);
}
