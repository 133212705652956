.block-nav {
  &__link {
    span {
      display: none;
    }
    a {
      text-decoration: none;
      display: inline-flex;
      align-items: center;
      gap: 2.5rem;
    }

    &__title {
      //background: linear-gradient(to right, var(--bs-secondary), var(--bs-secondary) 50%, var(--bs-dark) 50%);
      //background-clip: text;
      //-webkit-background-clip: text;
      //-webkit-text-fill-color: transparent;
      padding-right: 0.2em;
      background-size: 200% 100%;
      position: relative;
      background-position: 100%;
      //transition: background-position 500ms ease;
      //overflow: hidden;
      //-webkit-text-fill-color: transparent;
    }

    fstr-icon {
      min-width: 4em;
      min-height: 4em;
      opacity: 0;
      transition: transform 250ms;
      transform: translateX(-10px);
    }
    &.is--current {
      .block-nav__link__title {
        color: var(--bs-secondary);
      }
    }
    &:hover {
      @include media-breakpoint-up(sm) {
        .block-nav__link__title {
          background-position: 0 100%;
        }
      }
      fstr-icon {
        opacity: 1;
        transform: translateX(10px);
      }
      .block-nav__link__title {
        &::after {
          height: 3px;
          width: 100%;
          content: '';
          position: absolute;
          background-color: var(--bs-secondary);
          bottom: -15px;
          left: 0;
        }
      }
    }
  }
}

.navigation-list {
  display: flex;
  flex-flow: column;
  gap: spacer(4);

  @include media-breakpoint-up(md) {
    gap: spacer(5);
  }

  &__item {
  }
}
