.header__shape {
  max-width: 250px;
  display: block;
  float: right;
  margin-top: -0.6em;
  margin-right: 0.2em;
  z-index: -1;
  position: relative;

  @include media-breakpoint-down(sm) {
    max-width: 50vw;
  }
  &--right {
    right: 0;
    top: 0;
    position: absolute;
    max-width: 400px;
  }
}

.header.is--dark {
  background-color: var(--bs-blue-800);
  color: white;

  .highlight {
    //@extend .fs-display-2;

    //display: inline-block;
    //vertical-align: bottom;
    color: var(--bs-primary);
  }
}
