.block-contact {
  position: relative;
  //background-image: linear-gradient(0deg, rgba(red, 0.3) 0%, rgba(red, 0.3) 100%),
  //  radial-gradient(137% 51% at 72% 95%, red 0%, transparent 58.79%), url('/assets/gfx/halftone-sand.svg');
  background: radial-gradient(155.37% 149.91% at 65.54% -22.04%, #fff 39.58%, rgba(255, 255, 255, 0) 100%),
    url('/assets/gfx/halftone-sand.png');
  background-size: cover;

  &__inner {
    align-items: center;

    @include media-breakpoint-down(md) {
      flex-flow: column-reverse;
      margin-top: 3rem;
    }
  }

  &__figure {
    position: relative;
    display: flex;
    justify-content: flex-end;

    @include media-breakpoint-down(md) {
      justify-content: center;
      margin: spacer(2) 0 auto 0;
    }

    img {
      max-width: 400px;
    }
  }

  &__info {
    --spacer: #{spacer(2)};
    display: flex;
    gap: spacer(4) var(--spacer);
    flex-wrap: wrap;

    @include media-breakpoint-down(md) {
      flex-flow: column;
      justify-content: center;
    }

    @include media-breakpoint-up(lg) {
      justify-content: flex-end;
    }

    &__item {
      width: 100%;

      @include media-breakpoint-up(lg) {
        width: calc(50% - (var(--spacer) / 2));
      }
    }
  }
}
