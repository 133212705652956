.bullet-list {
  display: flex;
  flex-direction: column;

  &__item {
    padding: rem(30px) 0px;
    border-bottom: 1px solid var(--bs-secondary-bg);
    font-size: rem(24px);
    display: flex;
    text-wrap: pretty;

    &:first-child {
      padding-top: 0px;
    }
    @include media-breakpoint-down(lg) {
      padding: rem(15px) 0px;
    }

    &:before {
      content: '•';
      color: var(--bs-primary);
      padding-right: 20px;
      font-size: rem(24px);
    }
  }
}
