.container-size {
  @each $bp, $width in $container-sizes {
    @media screen and (min-width: #{map-get($container-max-widths, sm)}) {
      &-#{$bp} {
        --container-max-width: #{$width};
        max-width: $width;
      }
    }
  }
}

.container {
  --container-max-width: 100%;
  --container-padding-x: calc(var(--bs-gutter-x) * 0.5);
  padding-left: var(--container-padding-x);
  padding-right: var(--container-padding-x);

  @include media-breakpoint-down(md) {
    --container-padding-x: #{$container-padding-x};
  }
}

// Copied from bootstrap, but made it an variable instead of value only
// Responsive containers that are 100% wide until a breakpoint
@each $breakpoint, $container-max-width in $container-max-widths {
  @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
    // if container max-width is bigger than the grid-breakpoint-width
    @if (map-get($grid-breakpoints, $breakpoint) > $container-max-width) {
      %responsive-container-#{$breakpoint} {
        --container-max-width: #{$container-max-width};
      }
    }
  }
}
