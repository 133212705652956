.anim--stagger-list-item {
  --delay: 0s;
  --step-delay: 0.15s;
  --step-direction: 1;

  opacity: 0;
  transform: translate3d(min(calc(50% * var(--step-direction, 1)), 100px), 0, 0);
  transition: transform 0.4s cubic-bezier(0, 0, 0.12, 1), opacity 0.2s;
  transition-delay: calc(var(--delay, 0.2s) + var(--list-index) * var(--step-delay, 0.15s));

  // use css vars in timing utils...
  &--delay-2 {
    --delay: 0.5s;
  }

  &--step-delay-2 {
    --step-delay: 0.2s;
  }

  &--to-right {
    --step-direction: -1;
  }

  .scroll--entered & {
    opacity: 1;
    transform: translate3d(0, 0%, 0);
  }
}
