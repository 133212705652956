.block-case {
  display: flex;
  flex-direction: column;
  @include media-breakpoint-down(md) {
    overflow: hidden;
  }
}

.case-list {
  --bs-gutter-y: 60px;
}

.case-item {
  position: relative;

  .has--skew-bg::before {
    background-size: 400px auto;
  }

  &__button {
    @include abs(0);
    z-index: 1;
    &:hover {
      .show-case-button {
        opacity: 1;
      }
    }
  }

  &__image {
    display: block;

    img {
      aspect-ratio: var(--aspect-ratio);
      width: 100%;
      display: block;
    }
  }
}
