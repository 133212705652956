.subtitle-label {
  text-transform: uppercase;
  font-weight: 400;
  font-variation-settings: 'wght' 500;
  display: flex;
  align-items: center;

  &:before {
    content: '•';
    color: var(--bs-primary);
    padding-right: 8px;
    font-size: rem(24px);
  }
}
