.header-with-image {
  position: relative;
  background-color: var(--bs-yellow-100);

  &__figure {
    position: relative;
    display: block;

    @include media-breakpoint-down(md) {
      margin-top: 50px;
    }

    &__image {
      position: relative;
      z-index: 2;

      //min-height: 40vh;

      &__shape {
        position: absolute;
        z-index: -1;
        width: 40%;
        right: 15%;
        bottom: -20%;
        min-width: 25vw;

        .text-shape {
          display: block;
        }
      }
    }

    &__text {
      height: 100%;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      flex-flow: column;

      &::after {
        content: '';
        height: 45%;
        width: 100%;
        background: white;
        margin-bottom: -1px; // fix render lines
      }

      .big-text {
        padding: 0;

        &__title {
          white-space: nowrap;
          transform: translate(0, 12vw); // make up for rotation translation shift
          display: block;
          color: white;
        }
      }
    }
  }

  &__main {
    position: relative;
    z-index: 2;
  }

  .scroll-indicator {
    transform: rotate(-90deg) translateY(-100%);
    transform-origin: top right;
    position: absolute;
    top: 0;
    right: 20px;

    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  &.has--sand-overlay {
    &::before {
      content: '';
      top: auto;
      right: auto;
      max-width: 800px;
      max-height: calc(100% - 15vh);
    }
  }
}

.has--sand-overlay {
  position: relative;

  &::before {
    content: '';
    @include abs();
    background-image: url('/assets/gfx/halftone-white.png');
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.scroll-indicator {
  display: flex;
  gap: 12px;
  align-items: flex-start;
  height: 30px;

  &__arrow {
    display: block;
    transform: rotate(90deg) translate(-16%, -24%);
    width: 30px;
    transform-origin: top left;
    //transform: scaleX(-1) scwaleY(-1);
  }
}

.header-with-image.is--dark {
  background-color: var(--bs-blue-800);
  color: white;

  &.has--sand-overlay {
    &::before {
      background-image: url('/assets/gfx/halftone-glass.png');
      max-width: 1200px;
      opacity: 0.2;
    }
  }

  .highlight {
    @extend .fs-display-2;

    display: inline-block;
    vertical-align: bottom;
    color: var(--bs-primary);
  }
}
