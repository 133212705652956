app-workshop-card {
  display: contents;
}

.workshop-card {
  position: relative;
  opacity: 0.2;
  transition: opacity 0.3s cubic-bezier(0.23, 1, 0.32, 1);

  @include media-breakpoint-down(md) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &.set--active {
    opacity: 1;
    &::before {
      transform: rotate(4deg) translate3d(0, 0, 0);
    }
  }

  &__main {
    max-width: 350px;
  }

  &__title {
    @include font-size(42px); // random fontsize die je alleen hier tegenkomt huilen
  }

  &::before {
    transition: transform 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    transform-origin: top right;
    transform: rotate(0deg) translate3d(-10px, 20px, 0);
    --skew-bg-color: var(--bs-primary);
  }

  @media (hover: hover) {
    &:hover {
      opacity: 1;
      &::before {
        transform: rotate(4deg) translate3d(0, 0, 0);
      }
    }
  }
}
