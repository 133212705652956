.usp-list {
  --bs-gutter-x: 1rem;
  --bs-gutter-y: 1rem;

  &__item {
    display: flex;
  }
}

.usp-card {
  position: relative;
  padding: 1rem;
  min-height: 150px;
  display: flex;
  align-items: flex-start;
  flex-flow: column;

  width: 100%;

  &::before {
    @include abs(0);
    content: '';
    background-image: url('/assets/gfx/halftone-sand.png');
    background-size: 200% auto;
    background-position: 20% 70%;
    transform: rotate(1.88deg);
  }

  > * {
    position: relative;
    z-index: 2;
  }

  &__title {
    margin: 0;
  }

  &__icon {
    max-height: 130px;
    max-width: 140px;
    object-fit: contain;
    object-position: bottom;
  }
}
