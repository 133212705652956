.block-team {
  position: relative;

  &__row {
    @include media-breakpoint-down(md) {
      flex-direction: column-reverse;
    }
  }

  &__title {
    --shape-text-line-height: 0.2;
    line-height: 0.8;
  }

  &__intro {
    max-width: 440px;
    line-height: 1.4;

    p {
      @include get-font-size(5);
    }
  }

  &__slider {
    position: relative;

    @include media-breakpoint-down(md) {
      margin-right: var(--space-left);
    }

    &__next {
      @include abs(50%, 0, null, auto);
      transform: translate(0%, -50%);
      z-index: 10;

      @include media-breakpoint-up(xl) {
        right: auto;
        left: 50%;
        transform: translate(260px, -50%);
      }

      @include media-breakpoint-down(md) {
        // left: 100%;
        left: auto;
        right: spacer(3);
        transform: translate(-100%, -50%);
      }
    }
  }

  &__figure {
    @include abs(50%, null, null, 50%);
    @include size(65%, 80%);
    max-width: 420px;

    background-color: var(--bs-yellow-100);
    transform: translate3d(-40%, -50%, 0);

    @include media-breakpoint-down(md) {
      @include size(50%, 80%);
      transform: translate3d(-50%, -50%, 0);
    }

    &::before {
      @include abs(spacer(3), null, null, -#{spacer(3)});
      @include size(100%, 100%);

      background: url('/assets/gfx/halftone-orange.png') top left repeat-y;
      background-size: 100% auto;
      content: '';
      z-index: 4;
    }

    .squiggle {
      @include abs(50%, null, null, 50%);
      @include size(100%, auto);
      transform: translate3d(-80%, -50%, 0px);
      z-index: 5;
      max-width: 400px;
      margin: auto;
    }

    &__title {
      @include size(100%);
      @include abs(0, 0, 0, 0);

      overflow: hidden;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      z-index: 5;
      transform-origin: bottom right;

      &__rotate {
        writing-mode: vertical-lr;
        transform: rotate(-180deg) translate3d(-35%, -100%, -8px);
        text-align: center;
        position: absolute;
        white-space: pre;
        bottom: 0;
        right: 0;
        margin: 0;
        font-size: 18vh;
        opacity: 0;
        transition: transform 0.4s 0.2s ease-in-out, opacity 0.4s 0.3s ease-in-out;

        @include media-breakpoint-down(md) {
          font-size: 13vh;
        }
      }

      &.set--active {
        .block-team__figure__title__rotate {
          opacity: 1;
          transform: rotate(-180deg) translate3d(-35%, -1%, -8px);
        }
      }
    }
  }

  &__main {
    position: relative;
  }
}
