.splitting--word-in {
  //--word-total: 9;
  //--char-total: 41;
  //--splitting-duration: 1000ms;

  .char {
    //--char-index
    opacity: 0;
    transform: translate3d(0, 80%, 0);
    display: inline-block;
    //transition: transform calc((var(--duration) / var(--char-total)) * 100ms);
    transition: transform 0.4s cubic-bezier(0.42, 0, 0.37, 1), opacity 0.2s;
    transition-delay: calc(var(--word-index) * 0.02s);
  }

  .scroll--entered &,
  &.anim--active {
    .char {
      opacity: 1;
      transform: translate3d(0, 0%, 0);
    }
  }
}
