.header-play {
  position: relative;
  --gradient-color: #1c2029;
  //--gradient-color: red;
  overflow: hidden;

  &::before,
  &::after {
    content: '';
    background-image: radial-gradient(645% 715% at 130% 50%, transparent 0%, var(--gradient-color) 100%), url('/assets/play-dots.png');
    //background-image: url('/assets/play-dots.png');
    @include abs(-10%);
    background-size: auto 100%;
    //background-repeat: ;
    background-position: center right;
    left: auto;
    z-index: 1;
    width: 55vw;
    opacity: 0.3;
  }

  &::after {
    right: auto;
    background-position: center left;
    background-image: radial-gradient(645% 715% at 130% 50%, transparent 0%, var(--gradient-color) 100%), url('/assets/play-dots.png');
  }

  &__gradient {
    &::after {
      --offset: -10%;
      content: '';
      border-radius: 100%;
      background: radial-gradient(50% 50% at 50% 50%, var(--gradient-color) 0%, transparent 100%);
      display: block;
      @include abs(var(--offset));
      z-index: 2;
      margin: auto;
    }
  }

  &__inner {
    margin-top: 3vh;
    position: relative;
    z-index: 10;
  }

  &__title {
    position: relative;
    font-family: $font-family-sans-serif !important;

    > span {
      position: relative;
      z-index: 2;
      transform: rotate(-4deg);
      display: block;
    }

    &__shape {
      position: absolute;
      left: 50%;
      top: 50%;
      bottom: 0;
      right: 0;
      transform: translate(-50%, -50%);
      display: block;
      width: 100%;
      height: 130%;
      min-height: 200px;
      display: block !important;

      .text-shape {
        width: 100%;
        height: 100%;
      }
    }
  }
}

@keyframes FirstHalf {
  0% {
    transform: translateY(-100%) scaleY(0.5);
  }
  100% {
    transform: translateY(200%) scaleY(1.5);
  }
}

.simple-scroll-indicator {
  display: flex;
  justify-content: center;
  height: 4.5em;
  overflow: hidden;

  &::after {
    content: '';
    width: 2px;
    border-radius: 10px;
    height: 200px;
    background: #fff;
    animation-delay: 0.3s;
    animation-name: FirstHalf;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in;
    height: 3.5em;
  }
}
