.block-question {
  &__label {
    text-transform: uppercase;
    &:before {
      content: '•';
      color: var(--bs-primary);
      padding-right: 20px;
      font-size: rem(24px);
    }
  }
  &__main {
    @include media-breakpoint-down(md) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}
