[class*='has--skew-']:not(.has--skew-bg) {
  &::before {
    position: absolute;
    content: '';
    min-height: 15px;
    height: 3vw;
    width: 100%;
    background: linear-gradient(89.65deg, #ff6300 0.25%, #ff3f01 100%);
  }
}

.has--skew {
  &-tl {
    &::before {
      bottom: 100%;
      left: 0;
      -webkit-clip-path: polygon(0 0, 0 100%, 100% 0);
      clip-path: polygon(0 0, 0 100%, 100% 0);
      transform: translateY(100%);
    }
  }
  &-tr {
    &::before {
      bottom: 100%;
      transform: translateY(100%);
      left: 0;
      -webkit-clip-path: polygon(0 0, 100% 100%, 100% 0);
      clip-path: polygon(0 0, 100% 100%, 100% 0);
    }
  }
  &-bl {
    &::before {
      top: 100%;
      left: 0;
      -webkit-clip-path: polygon(0 0, 0 100%, 100% 0);
      clip-path: polygon(0 0, 0 100%, 100% 0);
    }
  }
  &-br {
    &::before {
      top: 100%;
      left: 0;
      -webkit-clip-path: polygon(0 0, 100% 100%, 100% 0);
      clip-path: polygon(0 0, 100% 100%, 100% 0);
    }
    &::after {
      top: 100%;
      left: 0;
      -webkit-clip-path: polygon(0 0, 100% 100%, 100% 0);
      clip-path: polygon(0 0, 100% 100%, 100% 0);
    }
  }
}

// Standalone variant (see example in contentblock service)
// top-right, bottom-right ...
$skew-clippaths: (
  'tr': polygon(0 0, 100% 100%, 100% 0),
  'tl': polygon(0 0, 0 100%, 100% 0),
  'br': polygon(0 0, 100% 100%, 100% 0),
  'bl': polygon(0 0, 0 100%, 100% 0),
);

[class*='skew--'] {
  position: absolute;
  min-height: 15px;
  height: 3vw;
  max-height: 50px;
  width: 100%;
  display: flex;
  border: none;

  &::before {
    content: '';
    background: linear-gradient(89.65deg, #ff6300 0.25%, #ff3f01 100%);
    display: block;
    height: 100%;
    width: 100%;
    z-index: 2;
  }
}

@each $name, $clippath in $skew-clippaths {
  .skew--#{$name} {
    z-index: 2;
    @if (str-index($name, 'b')) {
      bottom: 1px;
      transform: translateY(100%);
    }

    &::before {
      clip-path: $clippath;
    }
  }
}
