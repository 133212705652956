.block-play-cta-end {
  position: relative;
  overflow: hidden;
  // background-color: var(--bs-dark);

  @include media-breakpoint-down(sm) {
    padding: 55px 0;
  }

  &__arrow-1,
  &__icon-2 {
    pointer-events: none;
    fill: var(--bs-secondary);
  }

  &__arrow-1 {
    @include abs(0, null, null, 0);

    width: 98px;
    transform: translate(-180%, -67%) scaleX(-1) rotate(70deg);

    @include media-breakpoint-down(md) {
      width: 50px;
    }
  }

  &__icon-2 {
    @include abs(null, 0, 0, null);
    @include size(150px);
    transform: translate(50%, -25%);

    @include media-breakpoint-down(md) {
      @include size(50px);
      transform: translate(-10%, 65%);
    }
  }

  &__header {
    position: relative;
  }

  &__title {
    --letter-box-size: clamp(120px, 27vw, 420px);
    font-size: var(--letter-box-size);
    letter-spacing: 0.1ex;
    white-space: nowrap;
  }

  &__subtitle {
    --letter-box-size: clamp(calc(130px / 2.45), calc(27vw / 2.3), calc(420px / 2.3));
    font-size: var(--letter-box-size);
    white-space: nowrap;
    margin-bottom: 0;
    padding-top: 0.1ex;
    letter-spacing: normal;
  }

  &__content {
  }

  &__image {
    @include media-breakpoint-down(sm) {
      width: 60%;
      position: absolute;
      right: 0;
    }
  }
}
