$colors: (
  blue-800: #1c2029,
  blue: #007bff,
  indigo: #6610f2,
  purple: #6f42c1,
  pink: #e83e8c,
  red: #dc3545,
  orange: #ff6300,
  yellow: #ffc107,
  yellow-100: #e7e4de,
  green: #28a745,
  teal: #20c997,
  cyan: #00f0ff,
);

$theme-colors: (
  primary-600: #ff3f01,
  primary: color(orange),
  secondary-600: #03ccda,
  secondary: color(cyan),
  tertiary: color(yellow-100),
  success: color(green),
  info: color(blue),
  warning: color(yellow),
  danger: color(red),
  light: white,
  dark: color(blue-800),
  muted: #adb5bd,
);

$grays: (
  900: #212529,
  800: #343a40,
  700: #495057,
  600: #6c757d,
  500: #adb5bd,
  400: #ced4da,
  300: #dee2e6,
  200: #e9ecef,
  100: #f8f9fa,
);

//
$primary-bg-subtle: #e7f6fd; //tint-color(theme-color(primary), 90%)
$body-secondary-color: #757575;

//?---------------- Dark / Play theme ----------------
// PROJECTPATH/ClientApp/node_modules/bootstrap/scss/_variables-dark.scss
$body-color-dark: white;
$body-bg-dark: #1c2029;
//$body-secondary-color-dark:         rgba($body-color-dark, .75) !default;
//$body-secondary-bg-dark:            $gray-800 !default;
//$body-tertiary-color-dark:          rgba($body-color-dark, .5) !default;
//$body-tertiary-bg-dark:             mix($gray-800, $gray-900, 50%) !default;
//$body-emphasis-color-dark:          $white !default;
//$border-color-dark:                 $gray-700 !default;
//$border-color-translucent-dark:     rgba($white, .15) !default;
//$headings-color-dark:               inherit !default;
//$link-color-dark:                   tint-color($primary, 40%) !default;
//$link-hover-color-dark:             shift-color($link-color-dark, -$link-shade-percentage) !default;
//$code-color-dark:                   tint-color($code-color, 40%) !default;

// Other
//? Put own extra variables here
$primary-gradient: linear-gradient(89.65deg, theme-color(primary) 0.25%, theme-color(primary-600) 100%);
$secondary-gradient: linear-gradient(89.65deg, theme-color(secondary) 0.25%, theme-color(secondary-600) 100%);
