.video {
  position: relative;

  &__overlay {
    z-index: 1;
    @include abs(0);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity 0.2s;

    img {
      @include abs(0);
      object-fit: cover;
      height: 100%;
      width: 100%;
    }

    &.is--hidden {
      opacity: 0;
      pointer-events: none;
    }
  }
}

.fstr-youtube__container {
  // reset old stuff
  padding: 0 !important;
  height: auto !important;

  aspect-ratio: 1/0.779;
}
