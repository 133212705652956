.block-intro {
  //background-color: var(--bs-light);

  &__content {
    display: flex;
    flex-flow: column;
    gap: spacer(5);
  }

  &__text {
    gap: 40px;
    display: flex;
    flex-direction: column;

    &__paragraph {
      line-height: 1.1;
      // @include get-font-size(2);
      font-weight: 400;
      font-variation-settings: 'wght' 400;
      word-wrap: break-word;
    }
  }
  &__cta {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    align-content: flex-end;
    gap: 30px;

    @include media-breakpoint-down(lg) {
      justify-content: center;
      gap: 12px;
    }
  }
}
