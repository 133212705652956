.grid-overlay {
  @include fxd(0);
  z-index: 9999;
  pointer-events: none;

  .grid-showcase {
    // outline: 1px solid color(blue);

    &__inner {
      // outline: 1px solid color(pink);
      height: 100vh;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      background: #ff000026;
      outline: 1px solid #ff00004a;
    }
  }
}

.breakpoint-util-list {
  position: fixed;
  top: 0;
  right: 0;

  &__item {
    font-size: 30px;
    font-weight: 600;
    font-variation-settings: 'wght' 600;
    text-transform: uppercase;
    font-family: sans-serif;
    background: var(--bs-primary);
    color: white;
    padding: 10px 30px;
  }
}
