@font-face {
  font-family: 'polysans';
  src: url('/assets/fonts/polysans-bulky-webfont.woff2') format('woff2'), url('/assets/fonts/polysans-bulky-webfont.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'polysans';
  src: url('/assets/fonts/polysans-bulkyitalic-webfont.woff2') format('woff2'),
    url('/assets/fonts/polysans-bulkyitalic-webfont.woff') format('woff');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'polysans';
  src: url('/assets/fonts/polysans-median-webfont.woff2') format('woff2'), url('/assets/fonts/polysans-median-webfont.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'polysans';
  src: url('/assets/fonts/polysans-medianitalic-webfont.woff2') format('woff2'),
    url('/assets/fonts/polysans-medianitalic-webfont.woff') format('woff');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'polysans';
  src: url('/assets/fonts/polysans-neutral-webfont.woff2') format('woff2'),
    url('/assets/fonts/polysans-neutral-webfont.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'polysans';
  src: url('/assets/fonts/polysans-slim-webfont.woff2') format('woff2'), url('/assets/fonts/polysans-slim-webfont.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
