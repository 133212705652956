.has--dot {
  & span {
    display: flex;
    align-items: center;
    gap: 8px;
    text-align: center;

    &::before {
      content: '';
      @include size(5px);
      border-radius: 40px;
      background-color: currentColor;
    }
  }
  & em {
    padding-left: 8px;
  }
}
