.block-service {
  position: relative;
  padding: calc(var(--big-text-font-size) / 1.5) 0 calc(var(--big-text-font-size) / 2);

  &::before {
    @include abs(0, null, null, 0);
    background: url('/assets/gfx/bg-dots-orange.png') top left no-repeat;
    background-size: 100% auto;
    pointer-events: none;
    width: 100%;
    content: '';
    height: 110px;
    z-index: 10;
  }

  &__title {
    @include abs(0, null, null, 0);
    display: block;
    top: -120px;
    @include media-breakpoint-down(md) {
      top: -20px;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: spacer(4) 0;
    position: relative;

    @include media-breakpoint-up(md) {
      gap: spacer(6) 0;
    }

    @include media-breakpoint-up(lg) {
      gap: spacer(7) 0;
    }

    &__item {
      position: relative;
      z-index: 5;
      &:first-child {
        .service-info__image {
          transform: translateY(100%);
          bottom: 0;
        }
      }
      &:last-child {
        .service-info {
          bottom: 0;
          top: auto;
        }
      }

      &.set--active {
        width: 100%;
        z-index: 10;
        // transition: width 0.01s linear;

        .service-info {
          opacity: 1;
          pointer-events: all;
          transition: opacity 0.3s 0.01s ease-in-out;

          &__text {
            line-height: 2;
            opacity: 1;

            a {
              line-height: 2;
              &:hover {
                font-weight: 600;
                font-variation-settings: 'wght' 600;
              }
            }
          }
        }

        .block-service__button svg {
          opacity: 1;
          transform: translate3d(0, 0, 0);
        }
      }
      fstr-icon {
        opacity: 0;
        transition: transform 250ms;
        min-width: 4em;
        min-height: 4em;
      }

      &:hover {
        fstr-icon {
          opacity: 1;
          transform: translateX(10px);
        }

        .service-info__title::after {
          height: 3px;
          width: 100%;
          content: '';
          position: absolute;
          background-color: var(--bs-secondary);
          bottom: -15px;
          left: 0;

          @include media-breakpoint-down(md) {
            display: none;
          }
        }
      }
    }

    .block-service__button {
      display: inline-flex;
      cursor: pointer;
      align-items: center;
      position: relative;
      gap: 1.5em;
      width: 60%;
      @include media-breakpoint-down(md) {
        width: 100%;
      }
    }
  }
}

.service-info {
  @include abs(0, 0, null, null);
  width: 35%;
  padding-top: spacer(2);
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s cubic-bezier(0.23, 1, 0.32, 1);
  height: fit-content;

  @include media-breakpoint-down(md) {
    display: none;
  }

  &__text {
    position: relative;
    z-index: 5;
    opacity: 0;
    line-height: 2;
    transition: line-height 0.3s ease-in-out, opacity 0.3s ease-in-out;
    display: -webkit-box;
    -webkit-line-clamp: 10;
    -webkit-box-orient: vertical;
    overflow: hidden;
    @include media-breakpoint-down(md) {
      opacity: 1;
    }

    a {
      color: var(--bs-dark);
      text-decoration: none;
      @include media-breakpoint-down(md) {
        font-size: inherit;
        line-height: normal;
      }
    }
    p {
      margin-bottom: 0;
      font-size: inherit;
    }
  }

  .block-services__shape {
    @include abs(70%, null, null, 50%);

    transform: translate(-50%, -50%);
    z-index: 1;
    width: 80%;
  }

  &__title {
    position: relative;
  }
  &__image {
    width: 100%;
    img {
      max-width: 150px;
      float: right;
      object-fit: contain;
      display: block;
    }
    position: absolute;
    transform: translateY(-120%);
  }
}
