body {
  --big-text-font-size: 24vw;
}

app-big-text {
  pointer-events: none;
}

.big-text {
  position: relative;
  width: 100vw;
  overflow: hidden;
  padding: 8vw 0 0;
  pointer-events: none;
}

.big-text__wrapper {
  width: 110%;
  display: flex;
  transform: rotate(-4deg) translateX(-4vw) translateY(0vh);
  transform-origin: bottom left;
  flex-flow: column;

  &.set--center {
    justify-content: center;
    text-align: center;
  }

  &.set--right {
    justify-content: flex-end;
    text-align: right;
  }
}

.big-text__title {
  font-size: var(--big-text-font-size);
  font-variation-settings: 'wght' 700;
  line-height: 0.8;

  &.is--odd {
    transform: translate(-10%, 0px);
  }
  &.is--even {
    transform: translate(10%, 0px);
  }
}
