.block-team-v2__team-card {
  position: relative;
  padding-right: spacer(7);

  .team-card {
    justify-content: flex-end;
    transition: transform 0.4s ease-in-out;
    transform: translate3d(-15vw, 0, 0);
  }

  .team-card__figure img {
    height: 100%;
    max-height: 60vh;

    @include media-breakpoint-down(md) {
      max-height: 380px;
      margin-top: 50%;
    }
  }

  @include media-breakpoint-down(md) {
    padding-right: 0;
  }
}

.block-team-v2__slider {
  @include media-breakpoint-down(md) {
    transform: translateY(-10%);
    position: relative;
    z-index: 4;
  }
}

.block-team-v2__content {
  @include abs(50%, null, null, 0);

  transform: translateY(-50%);
  opacity: 0;
  filter: blur(4px);
  transition: opacity 0.4s ease-in-out, filter 0.5s ease-in-out;
  max-width: 440px;
  padding-left: spacer(3);
  pointer-events: none;

  @include media-breakpoint-down(md) {
    padding-right: spacer(5);
    top: 0;
    transform: translateY(0);
  }

  &.set--active {
    opacity: 1;
    pointer-events: all;
    filter: blur(0);
    transition: opacity 0.4s 0.3s ease-in-out, filter 0.5s ease-in-out;
  }
}

.block-team-v2__main {
  @include media-breakpoint-down(md) {
    position: relative;
    height: 280px;
    z-index: 5;
  }

  &__wrapper {
    @include abs(0, 0, 0, 0);
    z-index: 10;
    pointer-events: none;
  }

  .container {
    position: relative;
    height: 100%;
  }
}

.block-team-v2 .block-team-v2__figure {
  background-color: transparent;
  transform: translate3d(-20%, -50%, 0);
  max-width: 300px;
  width: 50%;

  @include media-breakpoint-down(md) {
    height: 50%;
    transform: translate3d(-50%, -30%, 0);
    z-index: 3;
  }

  &::before {
    left: 7ex;
    z-index: 10;
  }

  .squiggle {
    width: 80%;
    transform: translate3d(-50%, -50%, 0px);
    z-index: 11;

    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  .block-team__figure__title {
    height: 120%;

    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  .block-team__figure__title__rotate {
    bottom: auto;
    right: auto;
    left: 3rem;
    top: 0;
    color: var(--bs-tertiary);
    transform: rotate(-180deg) translate3d(22%, -1%, -8px);
    animation: nameAnim 0.4s ease-in-out both;
  }
}

.block-team-v2__content__skills {
  position: relative;
  display: inline-block;
  width: auto;
  padding: 60px spacer(4) 0;

  @include media-breakpoint-down(md) {
    transform: translate(-#{spacer(4)}, 0);
    padding: 40px spacer(3) 0;
    font-size: 0.9em;
  }

  li::marker {
    color: var(--bs-primary);
  }

  &::before {
    @include abs(0, 0, 0, 0);
    @include size(100%);
    z-index: -1;
    background-color: var(--bs-secondary);
    display: block;
    content: '';
    pointer-events: none;
    transform: rotate(4deg);
  }

  // markdown content style overrides
  ul {
    display: flex;
    flex-flow: column;
    gap: 0.5em;

    li {
      font-variation-settings: 'wght' 600;
      font-weight: 600;
    }
  }

  &__title {
    margin-bottom: 0;
    transform: translate(-#{spacer(5)}, 17px) rotate(4deg);
    color: var(--bs-light);

    @include media-breakpoint-down(md) {
      transform: translate(-1rem, 17px) rotate(4deg);
    }
  }
}

.block-team-v2__team-card {
  width: 100%;
  filter: blur(5px);
  opacity: 0;
  transition: filter 0.6s ease-in-out, opacity 0.6s ease-in-out;

  @include media-breakpoint-up(md) {
    opacity: 1;
  }

  &.set--active {
    filter: blur(0);
    opacity: 1;
    .team-card {
      transform: translate3d(0, 0, 0);
    }
  }
}

.block-team-v2__controls {
  @include abs(80%, null, null, 50%);
  transform: translate(-50%, -50%);
  width: 100%;
  pointer-events: none;
  z-index: 100;

  @include media-breakpoint-down(md) {
    top: 520px;
    left: auto;
    right: 0;
    transform: translate(-30px, -10px);
  }

  .container {
    display: flex;
    justify-content: flex-end;
    transform: translateX(5%);
  }

  .button {
    position: relative;
    z-index: 5;
    pointer-events: all;
  }

  .show-case-button {
    opacity: 1;
    padding: 1px;
    z-index: 2;
    left: auto;
    font-style: italic;
    font-weight: 500;
    font-variation-settings: 'wght' 500;
    font-family: $font-family-serif;
    text-transform: none;
    transform: translate(50%, -90%);

    @include media-breakpoint-up(md) {
      font-size: 1.2em;
    }
  }
}

.block-team-v2__team-card {
}

@keyframes nameAnim {
  0% {
    transform: rotate(-180deg) translate3d(22%, 100%, -8px);
  }
  100% {
    transform: rotate(-180deg) translate3d(22%, -1%, -8px);
  }
}
