@each $name, $value in $button-sizes {
  .button--#{$name} {
    --button-size: #{rem($value)};
    --button-padding-x: #{rem($value * 0.5)};

    @include get-font-size(body-#{$name});

    &.button-icon {
      @include size(rem($value));
      min-width: rem($value);
    }
  }
}

.button--lg {
  font-size: 1.1rem;
}
