.halftone-shape-overlay {
  position: relative;

  > * {
    position: relative;
    z-index: 2;
  }

  &::after {
    content: '';
    border-radius: 50%;
    background: #1c2029;
    //background: red; // debug
    filter: blur(50px);
    display: block;
    width: 200%;
    height: 200%;
    transform: translate(-25%, -25%);
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
  }

  &--1 {
    @extend .halftone-shape-overlay;

    &::after {
      filter: blur(100px);
      width: 180%;
      height: 100%;
      transform: translate(-20%, -10%) rotate(45deg);
      z-index: 1;
    }
  }
}

@include media-breakpoint-down($topbar-column-breakpoint) {
  .topbar__actions {
    .halftone-shape-overlay::after {
      display: none;
    }
  }
}
