$topbar-column-breakpoint: 'md';

@import './utils';
@import './halftone-shape-overlay';

app-topbar {
  display: contents;
}

.main-nav {
  @include fxd(0, 0, auto, 0);
  z-index: 999;
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);

  &__overlay {
    display: none;
    @include fxd(0, 0, 0, 0);
    height: 100dvh;
    z-index: -1;
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.2s;

    &.is--open {
      z-index: 5;
      pointer-events: auto;
      opacity: 1;
    }
  }
}

.hamburger-button {
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative;
  z-index: 10;
}

.topbar {
  &__logo {
    max-width: 300px;
    max-height: 106px;
    display: inline-block;
    margin-left: -21px;
    padding-top: 0.1em; // font x height correction
    position: relative;
    z-index: 10;

    @include media-breakpoint-down(md) {
      max-width: 205px;
      max-height: 70px;
      margin-left: -15px;
    }

    img {
      width: 100%;
      height: auto;
      display: block;
    }
  }

  &__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 15px;
    gap: 20px;
  }

  &__actions {
    display: flex;
    gap: 30px;
  }

  &.theme--light {
    color: white;
  }
  &.theme--dark {
    color: black;
  }
}

.nav-overlay {
  --nav-overlay-padding-bottom: 40px;

  background: white;
  display: flex;
  overflow-x: hidden;
  overflow-y: auto;

  &__inner {
    display: flex;
  }

  &__meta {
    background: white;
    position: relative;
    z-index: 10;
    padding-bottom: var(--nav-overlay-padding-bottom);
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    justify-content: flex-end;
    gap: 1em;

    @include media-breakpoint-down($topbar-column-breakpoint) {
      --offset-right: calc(0.5 * var(--bs-gutter-x));
      margin-left: calc(var(--offset-right) * -1);
      width: calc(100% + var(--offset-right) * 2);
      max-width: none;
      padding-right: calc(var(--bs-gutter-x) * 1);
      padding-left: calc(var(--bs-gutter-x) * 1);

      order: 2;
      justify-content: flex-start;
      padding-top: 20px;
    }
  }

  &__menu {
    position: relative;
    padding-bottom: var(--nav-overlay-padding-bottom);

    &::after {
      content: '';
      background: var(--bs-blue-800);
      @include abs(0, 0, 0, 0);
      background-image: linear-gradient(0deg, alpha(var(--bs-blue-800), 0.3) 0%, alpha(var(--bs-blue-800), 0.3) 100%),
        radial-gradient(137% 51% at 72% 95%, var(--bs-blue-800) 0%, transparent 58.79%), url('/assets/gfx/halftone-glass.png');
      background-repeat: no-repeat;
      background-size: cover;
      width: calc(100% + #{overflow-part-width()}); // TODO pull right thingy
      top: calc(var(--topbar-height) * -1);
      transition: background-position 4s ease-out;

      .is--open & {
        background-position: 10%;
      }

      @include media-breakpoint-down($topbar-column-breakpoint) {
        left: calc(var(--container-padding-x) * -1);
      }
    }

    &__inner {
      position: relative;
      z-index: 2;
      display: flex;
      flex-flow: column;
      justify-content: flex-end;
      align-items: flex-end;
      gap: min(12vh, 70px);
      height: 100%;
      //overflow-x: hidden;
      //overflow-y: auto;
      //max-height: calc(100dvh - var(--topbar-height) - var(--nav-overlay-padding-bottom));

      @include media-breakpoint-down($topbar-column-breakpoint) {
        align-items: flex-start;
        text-align: start;

        .menu-list {
          align-items: flex-start;
        }
        .social-list {
          justify-content: flex-start;
        }
      }
    }
  }
}

.menu-list {
  display: flex;
  flex-flow: column;
  align-items: flex-end;

  &__item {
    overflow: hidden;
    line-height: 1.3;

    fstr-link {
      display: contents;
    }

    &__link {
      --list-item-delay: calc(var(--list-item-index, 0) * 0.05s);
      //--list-item-delay: 10s;
      //padding: 0.1em 0;
      display: inline-block;
      color: white;
      text-decoration: none;

      opacity: 0;
      transform: translate3d(0, 100%, 0);
      transition: opacity 0.2s var(--list-item-delay), transform 0.5s var(--list-item-delay), color 0.2s 0s;

      .is--open & {
        opacity: 1;
        transform: translate3d(0, 0%, 0);
      }

      &:hover,
      &.is--current {
        color: var(--bs-primary);
      }
    }
  }
}

.social-list {
  display: flex;
  flex-wrap: wrap;
  column-gap: 30px;
  position: relative;
  z-index: 2;
  justify-content: flex-end;
}

.topbar-menu-list {
  display: flex;
  gap: spacer(3);
}

.nav-link {
  text-decoration: none;
  color: inherit;

  &--active {
    color: var(--bs-primary);
  }
}

.topbar {
  .topbar-menu-list__item {
    transition: all 0.15s;
    transition-delay: calc(var(--list-item-index, 0) * 0.05s);
  }

  .topbar__logo {
    transition: all 0.15s;
    transform-origin: left center;
  }

  &.is--collapsed {
    .topbar-menu-list__item {
      transform: translate(20px, 0px);
      opacity: 0;
      pointer-events: none;
    }

    .topbar__logo {
      opacity: 0;
      transform: scale(0.9);
      pointer-events: none;
    }
  }
}
