app-team-card {
  display: contents;
}

.team-card {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 1rem;

  &__figure {
    position: relative;
    z-index: 20;

    img {
      max-height: 75vh;
      width: auto;

      aspect-ratio: 1/1.2;
      object-fit: contain;

      @include media-breakpoint-down(md) {
        max-height: 55vh;
      }
    }
  }

  &__header {
    @include abs(10%, null, null, 50%);
    @include size(100%, 80%);
    background-color: var(--bs-yellow-100);
    transform: translate3d(-30%, 0, -20px);
    max-width: 400px;

    @include media-breakpoint-down(md) {
      transform: translate3d(-40%, 0, 0);
      max-width: 50vw;
    }

    &::before {
      @include abs(0, null, null, 0);
      @include size(100%, 100%);

      background: url('/assets/gfx/halftone-orange.png') top left repeat-y;
      background-size: 100% auto;
      transform: translate3d(-#{spacer(4)}, #{spacer(4)}, 0px);
      //  transform: translate3d(-#{spacer(3)}, #{spacer(3)}, 0px);
      // transition: transform 0.3s cubic-bezier(0.445, 0.05, 0.55, 0.95);
      content: '';
      z-index: 4;
    }

    .squiggle {
      @include abs(50%, null, null, 50%);
      @include size(100%, auto);
      transform: translate3d(-80%, -70%, 0px);
      z-index: 5;
    }
  }

  &__title {
    @include size(100%);

    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    position: relative;
    z-index: 5;
    transform-origin: bottom right;

    &__rotate {
      writing-mode: vertical-lr;
      transform: rotate(-180deg) translate3d(-35%, -1%, -8px);
      text-align: center;
      position: absolute;
      white-space: pre;
      bottom: 0;
      right: 0;
      margin: 0;
      font-size: 18vh;
      color: var(--bs-light);

      @include media-breakpoint-down(md) {
        font-size: 14vh;
      }
    }
  }

  &.set--active {
  }
}
