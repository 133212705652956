.block-carroussel {
  color: var(--bs-light);
  background-color: var(--bs-dark);
  position: relative;

  &__header {
    align-items: center;
    justify-content: space-between;

    @include media-breakpoint-down(md) {
      flex-direction: column;
      align-items: flex-start;
      gap: 3ex;
    }
  }

  &__nav-button {
    @include media-breakpoint-down(md) {
      @include abs(50%, null, null, null);

      transform: translate(0, -50%);
      z-index: 100;

      &.type--prev {
        left: 0;
      }

      &.type--next {
        right: 0;
      }

      &:disabled {
        opacity: 0;
      }
    }
  }
}
