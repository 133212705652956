.splitting--letter-in {
  //--word-total: 9;
  //--char-total: 41;
  //--splitting-duration: 1000ms;

  .char {
    //--char-index
    //--char-duration: calc(var(--splitting-duration) / var(--char-total));

    transform: translate3d(0, 125%, 0);
    display: inline-block;
    transition: transform 0.4s cubic-bezier(0.42, 0, 0.37, 1);
    transition-delay: calc((var(--char-index) * max(((1 - (var(--char-index) / 100))), 0.3)) * 0.02s);
    //var(--char-total)
    //min(var(--char-index), 30)
  }

  .scroll--entered &,
  &.anim--active {
    .char {
      transform: translate3d(0, 0%, 0);
    }
  }
}
