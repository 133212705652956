.block-featured {
  position: relative;
  background-color: var(--bs-dark);
  z-index: 0;

  &__image {
    img {
      transform: translateY(5px);
    }
  }
  .markdown-content,
  .markdown-content h3 {
    color: var(--bs-light);
  }
  &::after {
    content: '';
    background: var(--bs-dark);
    @include abs(null, null, 0, 0);
    background-image: linear-gradient(
        179deg,
        hsla(var(--bs-dark-h), var(--bs-dark-s), var(--bs-dark-l), 1) 0%,
        hsla(var(--bs-dark-h), var(--bs-dark-s), var(--bs-dark-l), 0) 66%
      ),
      radial-gradient(70% 291% at 102% 7%, var(--bs-dark) 30%, transparent 48.79%), url(/assets/gfx/halftone-glass.png);
    background-repeat: no-repeat;
    z-index: -1;
    background-size: cover;
    @include size(50%, 75%);
    @include media-breakpoint-down(md) {
      width: 100%;
    }
  }
}
