.underline {
  position: relative;
  display: inline-block;

  &::after {
    --underline-height: 0.5em;
    content: '';
    background-image: url('/assets/gfx/underline-primary.svg');
    @include abs(auto, 0, calc(var(--underline-height) * -0.6), 0);
    height: var(--underline-height);
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
}
