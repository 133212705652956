.chip {
  --chip-background-color: var(--bs-secondary);
  --chip-color: white;

  @include size(2.5em);
  background: var(--chip-background-color);
  color: var(--chip-color);
  fill: var(--chip-color);
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  text-align: center;

  .icon {
    width: 60%;
    height: 60%;
    margin: auto;
  }
  &--xl {
    @include size(rem(80px));
  }
  &--lg {
    @include size(rem(60px));
  }

  &--xs {
    @include size(rem(18px));
  }
  &--xxs {
    @include size(rem(12px));
    padding-bottom: 0ex;
  }

  @each $name, $color in $theme-colors {
    &.chip--#{$name} {
      --chip-background-color: #{$color};
      --chip-background-color-alpha: #{rgba($color, 0.09)};
    }
  }
}
