.content-block-list__item {
  //outline: 4px solid red;
  // z-index: 2;
}

div[class^='gsap-marker'],
div[class*=' gsap-marker'] {
  background-color: white;
  min-width: 250px;
  text-align: right;
}
