$animation-delay: (
  1: 200ms,
  2: 500ms,
  3: 600ms,
  4: 800ms,
  5: 1200ms,
);

@each $name, $delay in $animation-delay {
  .delay--#{$name} {
    --delay: #{$delay};
  }
}

$animation-step-delay: (
  1: 0.1s,
  2: 0.15s,
  3: 0.2s,
);

@each $name, $delay in $animation-step-delay {
  .step-delay--#{$name} {
    --step-delay: #{$delay};
  }
}
