.button.button-link {
  background: transparent;
  color: var(--button-background);
  padding-left: 0;
  padding-right: 0;
  border-radius: 0;
  text-transform: unset;
  font-weight: 500;
  font-variation-settings: 'wght' 500;
  font-size: 1em;

  fstr-icon {
    min-width: 1.5em;
    min-height: 1.5em;

    .icon {
      fill: currentColor;
    }
  }

  @media (hover: hover) {
    &:hover {
      background: transparent;
      color: var(--button-background-darker);
    }
  }
}
