.app {
  position: relative;
}

.app__router {
  position: relative;
  min-height: calc(var(--vh, 1vh) * 100);
  &:has(app-content-block-contact) {
    min-height: calc(var(--vh, 1vh) * 75);
  }
}
