// Imports
@import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,400;0,700;0,900;1,400;1,700;1,900&family=PT+Sans:ital,wght@0,400;0,700;1,400&display=swap');

// Bootstrap
$font-size-base: 16;

$font-family-sans-serif: 'polysans', sans-serif;
$font-family-serif: 'stinger-variable', sans-serif;

// Tip: Use goldenratio to generate font base sizes
// https://type-scale.com/
$font-sizes: (
  1: 78px,
  2: 72px,
  3: 58px,
  4: 37px,
  // below not used?
  5: 32px,
  6: 24px,
  body-xs: 14px,
  body-sm: 16px,
  body-md: 18px,
  body-lg: 24px,
  //body-xl: 26px,,
);

$display-font-sizes: (
  0: 160px,
  1: 130px,
  2: 110px,
  3: 100px,
);

$headings-font-family: $font-family-sans-serif;
$headings-font-weight: 400;
$headings-line-height: 1.1;
$headings-margin-bottom: 0.6em;

$display-font-family: $font-family-serif;
$display-font-style: italic;
$display-font-weight: 300;
$display-line-height: $headings-line-height;

$font-family-base: $font-family-sans-serif;
$font-weight-base: 500;

$lead-font-size: map-get($font-sizes, body-lg);
$lead-font-weight: 600;

$text-muted: var(--bs-gray-600);

// Other
//? Put own extra variables here
