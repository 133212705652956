.background-dots {
  height: calc(var(--section-inset-value) * 2 + 50%);
  position: absolute;
  top: calc(var(--section-inset-value) * -1);
  bottom: calc(var(--section-inset-value) * -1 + -20px);
  left: -20px;
  background-image: url('/assets/gfx/halftone-glass.png');
  width: 100%;
  background-size: 180% auto;
  background-repeat: no-repeat;
  margin-top: auto;
  z-index: 1;

  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 130%;
    height: 30%;
    border-radius: 100%;
    background: var(--bs-blue-800);
    margin-top: -10%;
    filter: blur(21px);
  }

  &::after {
    transform: rotate(80deg);
    margin-top: 40%;
    right: -80%;
    height: 60%;
    filter: blur(51px);
  }

  .theme--primary & {
    &::before,
    &::after {
      background: var(--bs-primary);
    }
  }
  .theme--sand & {
    &::before,
    &::after {
      background: #e7e4de;
    }
  }
}
