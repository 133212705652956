.splitting {
  // fix bugs concerning putting letters in spans (this may change spacing on safari)
  font-kerning: none;
  -webkit-text-rendering: optimizeSpeed;
  text-rendering: optimizeSpeed;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  //line-height: 1;

  //&:not(.splitting--loaded) {
  //  opacity: 0;
  //}

  .word {
    display: inline-block;
    overflow: hidden;
    vertical-align: bottom;

    // Add some space around the words and reset it to not cut of chars
    padding: 0.25em 0.1em;
    margin: -0.25em -0.1em;

    .char {
      // Add some space around the words and reset it to not cut of chars
      padding: 0.25em 0.1em;
      margin: -0.25em -0.1em;
    }
  }

  app-text-shape {
    display: contents;

    .text-shape__label .word {
      // padding: 0.1em 0.1em;
    }
    //.text-shape {
    //  min-height: 0;
    //  line-height: 0.7;
    //}
  }
}
