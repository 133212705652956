.play-slide {
  overflow: hidden;
  --accent-color: var(--bs-primary);

  @include media-breakpoint-down(md) {
    position: relative;
  }

  > .section-inset-y {
    @include media-breakpoint-up(md) {
      --section-inset-value: 13vw;
    }
  }

  &__letter {
    &-container {
      //--letter-box-size: clamp(160px, 100vw, 30vw);
      --letter-box-size: clamp(140px, 30vw, 360px);
      @include media-breakpoint-up(md) {
        position: relative;
      }
    }

    font-family: $font-family-serif;
    font-weight: 700;
    font-variation-settings: 'wght' 700;
    font-size: var(--letter-box-size);
    line-height: 1;
    text-align: center;
    display: inline-block;
    width: 100%;
    margin-bottom: -0.15em;

    &__figure {
      @include abs(calc(var(--section-inset-value) * -1), 0, calc(var(--section-inset-value) * -1), 0);
      overflow: hidden;

      @include media-breakpoint-down(md) {
        bottom: calc(var(--section-inset-value) * -1 + 15vw);
      }

      img {
        @include abs(0);
        left: auto;
        max-width: var(--letter-box-size);
        object-fit: contain;
        object-position: bottom center;
        height: 100%;
        max-height: 80vh;
        object-fit: contain;

        @include media-breakpoint-down(md) {
          height: 120%;
          margin: auto;
          transform: translate(0, 0%);
          margin-bottom: 0;
        }
      }
    }
  }

  &__title {
    --play-figure-size: 1.7em;
    position: relative;
    display: flex;
    gap: 35px;
    align-items: flex-start;
    padding-top: spacer(4);
    max-width: 70vw;

    &__text {
      display: flex;
      align-items: flex-start;
      width: fit-content;
    }

    &__icon {
      --figure-size: 30%;
      min-width: var(--figure-size);
      min-height: var(--figure-size);
      margin-left: -60px;
      fill: var(--accent-color);
      aspect-ratio: var(--icon-width) / var(--icon-height);
      transform: translate(0, 100%);
      width: var(--figure-size);

      &.aspect-ratio-icon-doodle-arrow-corner-right {
        transform: translate(0, 20%);
      }

      @include media-breakpoint-down(md) {
        --figure-size: 5vw;
        fill: transparent;
      }
    }

    &__figure {
      //position: absolute;
      //right: 0;
      //top: 0;
      min-width: var(--play-figure-size);
      min-height: var(--play-figure-size);
      margin-left: -30px;
      fill: var(--accent-color);
    }
  }
}

.play-slide {
  --accent-color: var(--bs-primary);

  &.theme--dark {
    --accent-color: var(--bs-secondary);
    .underline::after {
      background-image: url('/assets/gfx/underline-primary.svg');
    }
  }
  &.theme--dark-2 {
    --accent-color: var(--bs-secondary);

    .play-slide__letter {
      color: white;
      background: none;
      -webkit-text-fill-color: currentColor;
    }

    .underline::after {
      background-image: url('/assets/gfx/underline-primary.svg');
    }
  }
  &.theme--primary {
    --accent-color: var(--bs-secondary);
    background: linear-gradient(90deg, #ff6300 0.23%, #ff3d00 99.7%);

    .text-primary-gradient,
    .text-primary-gradient .char {
      background: var(--bs-secondary);
      background-clip: text;
    }

    .play-slide__letter {
      color: white;
      background: none;
      -webkit-text-fill-color: currentColor;
    }

    .underline::after {
      background-image: url('/assets/gfx/underline-secondary.svg');
    }
  }

  &.theme--sand {
    --accent-color: var(--bs-secondary);
    background-color: #e7e4de;
    color: var(--bs-dark);

    .underline::after {
      background-image: url('/assets/gfx/underline-primary.svg');
    }
  }
}

.play-word-container {
  overflow: hidden;
}

.play-word {
  --scroll-indicator-height: 35px;
  justify-content: end;
  align-items: center;

  @include media-breakpoint-down(md) {
    margin-right: var(--scroll-indicator-height);
  }
  @include media-breakpoint-down(sm) {
    align-items: center;
  }
  &__letters {
    display: flex;
    @include media-breakpoint-down(sm) {
      flex-flow: column;
    }
  }
  &__icon {
    --icon-size: 12em;
    min-width: var(--icon-size);
    min-height: var(--icon-size);
    fill: var(--bs-secondary);
    width: var(--icon-size);

    @include media-breakpoint-down(sm) {
      transform: rotate(30deg);
      position: absolute;
      top: -420px;
      --icon-size: 7em;
      left: -30px;
      bottom: 0;
    }
  }

  &__text {
    display: flex;
    align-items: center;
    position: relative;
    gap: 1.5rem;
    @include media-breakpoint-down(sm) {
      flex-flow: column;
    }
  }
  &__word {
    color: var(--bs-light);
    text-transform: uppercase;

    @include media-breakpoint-down(lg) {
      font-size: 4.5vw;
    }
  }
  &__scroll-indicator {
    .scroll-indicator {
      transform: rotate(-90deg) translateY(-100%);
      transform-origin: top right;
      position: absolute;
      top: 0;
      right: 20px;

      @include media-breakpoint-down(xl) {
        display: none;
      }
    }
  }
}
app-content-block-play-slide {
  position: relative;
}
.underline::after {
  background-image: url('/assets/gfx/underline-secondary.svg');
}

// todo eigen variant background-dots(?)
.background-dots-play-slide {
  height: calc(var(--section-inset-value) * 2 + 50%);
  position: absolute;
  top: calc(var(--section-inset-value) * -1);
  bottom: calc(var(--section-inset-value) * -1 + -20px);
  left: -20px;
  background-image: url(/assets/gfx/halftone-glass.png);
  width: 40%;
  background-size: 180% auto;
  background-repeat: no-repeat;
  margin-top: auto;
  z-index: -1;

  @include media-breakpoint-down(sm) {
    width: 100%;
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 130%;
    height: 30%;
    border-radius: 100%;
    background: var(--bs-blue-800);
    margin-top: -10%;
    filter: blur(21px);
  }

  &::after {
    transform: rotate(80deg);
    margin-top: 40%;
    right: -80%;
    height: 60%;
    filter: blur(51px);
  }
}

//.play-slide__letter-container {
//  --letter-box-size: clamp(240px, 100vw, 400px);
//  @include media-breakpoint-down(lg) {
//    --letter-box-size: clamp(160px, 100vw, 300px);
//  }
//  @include media-breakpoint-down(md) {
//    --letter-box-size: clamp(160px, 100vw, 220px);
//  }
//  @include media-breakpoint-down(sm) {
//    --letter-box-size: clamp(160px, 100vw, 50vw);
//  }
//}

.play-slide__letter.splitting--word-in .char {
  transform: translate3d(0, 30%, 0);

  .scroll--entered &,
  &.anim--active {
    transform: translate3d(0, 0%, 0);
  }
}
