.anim-fade-up {
  opacity: 0;
  transform: translate3d(0, 20%, 0);
  transition: transform 0.5s var(--delay, 0s), opacity 0.5s var(--delay, 0s);

  .scroll--entered &,
  &.anim--active {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.splitting--fade-lines {
  .word {
    transform: translate3d(0, 20%, 0);
    transition: transform 0.5s var(--delay, 0s), opacity 0.5s var(--delay, 0s);
    transition-delay: calc(var(--line-index) * 100ms);
    opacity: 0;
  }

  .scroll--entered &,
  &.anim--active {
    .word {
      opacity: 1;
      transform: translate3d(0, 0, 0);
      //--line-index
    }
  }
}

.anim--throw-image {
  transform: rotate3d(0, 0, 1, calc((-5deg) * var(--step-direction, 1))) translate3d(0, 30%, 0);
  transition: transform 1s var(--delay, 0s);
  transition-delay: calc(var(--delay, 0s) + var(--list-index) * var(--step-delay, 0s));
  transition-timing-function: cubic-bezier(0.3, 0.13, 0.46, 0.88);

  &.has--skew-bg::before {
    transition: transform 1s var(--delay, 0s);
    transition-delay: calc((var(--delay, 0s) + var(--list-index) * var(--step-delay, 0s)) + 0.2s);
    transform: rotate3d(0, 0, 1, 0deg) translate3d(0, 30%, 0);
  }

  .scroll--entered &,
  &.anim--active {
    transform: rotate3d(0, 0, 1, 0) translate3d(0, 0, 0);

    &.has--skew-bg::before {
      transform: rotate3d(0, 0, 1, var(--rotation)) translate3d(0, 0, 0);
    }
  }
}
