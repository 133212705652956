.block-partner {
  width: 100%;
}

.partner {
  --partner-image-max-width: 130px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  &__image {
    --chrome-renderbug-offset: 10px;
    --corrected-background-size: calc(100% - 1px) calc(100% - 1px);
    --bg-color-1: #1c2029;
    //--corrected-background-size: 100% 100%;
    width: 100%;
    max-width: var(--partner-image-max-width);
    aspect-ratio: 1/1;
    // Vervang de witte kleur van de achtergrond kleur van het blok om mooi rersultaat te krijgen
    background: linear-gradient(0deg, var(--bg-color-1) 0%, var(--bg-color-1) 100%),
      linear-gradient(0deg, var(--bg-color-1) 0%, var(--bg-color-1) 100%), var(--logo-image-url), linear-gradient(0deg, #fff 0%, #fff 100%);
    background-size: var(--corrected-background-size), var(--corrected-background-size), calc(100% - 3px) calc(100% - 3px), 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-blend-mode: overlay, color, normal, normal;
    //background-size: cover, cover, 97% 97%;
    filter: url(#monochrome);

    &__test {
      @include abs(0);
      margin: auto;
      border: 2px solid white;
      width: 100%;
      max-width: calc(var(--partner-image-max-width) + 1px);
    }
  }

  &::before {
    //content: '';
    @include abs(0);
    background-image: var(--logo-image-url);
    //background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    opacity: 0;
    z-index: 2;
    transition: opacity 0.2s;
    max-width: 130px;
    margin: auto;
    pointer-events: none;
  }

  &:hover {
    &::before {
      opacity: 1;
    }
  }
}
