%svg-common {
  background: url("/assets/dist/svg/scss/svg/sprite.css.svg") no-repeat;
  display: inline-block;
}

.icon-alert-circle {
  --icon-width: 25;
  --icon-height: 24;
  --relative-pos: 0 0;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-icon-alert-circle {
  --icon-width: 25;
  --icon-height: 24;
}
.icon-alert-octagon {
  --icon-width: 25;
  --icon-height: 24;
  --relative-pos: 0 0.1588246972404209%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-icon-alert-octagon {
  --icon-width: 25;
  --icon-height: 24;
}
.icon-alert-triangle {
  --icon-width: 25;
  --icon-height: 24;
  --relative-pos: 0 0.3176493944808418%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-icon-alert-triangle {
  --icon-width: 25;
  --icon-height: 24;
}
.icon-arrow-down {
  --icon-width: 25;
  --icon-height: 24;
  --relative-pos: 0 0.47647409172126265%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-icon-arrow-down {
  --icon-width: 25;
  --icon-height: 24;
}
.icon-arrow-left {
  --icon-width: 25;
  --icon-height: 24;
  --relative-pos: 0 0.6352987889616836%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-icon-arrow-left {
  --icon-width: 25;
  --icon-height: 24;
}
.icon-arrow-right {
  --icon-width: 25;
  --icon-height: 24;
  --relative-pos: 0 0.7941234862021044%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-icon-arrow-right {
  --icon-width: 25;
  --icon-height: 24;
}
.icon-arrow-up {
  --icon-width: 25;
  --icon-height: 24;
  --relative-pos: 0 0.9529481834425253%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-icon-arrow-up {
  --icon-width: 25;
  --icon-height: 24;
}
.icon-check {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 1.111772880682946%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-icon-check {
  --icon-width: 24;
  --icon-height: 24;
}
.icon-chevron-down {
  --icon-width: 25;
  --icon-height: 24;
  --relative-pos: 0 1.2705975779233671%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-icon-chevron-down {
  --icon-width: 25;
  --icon-height: 24;
}
.icon-chevron-left {
  --icon-width: 25;
  --icon-height: 24;
  --relative-pos: 0 1.429422275163788%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-icon-chevron-left {
  --icon-width: 25;
  --icon-height: 24;
}
.icon-chevron-right {
  --icon-width: 25;
  --icon-height: 24;
  --relative-pos: 0 1.5882469724042088%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-icon-chevron-right {
  --icon-width: 25;
  --icon-height: 24;
}
.icon-chevron-up {
  --icon-width: 25;
  --icon-height: 24;
  --relative-pos: 0 1.7470716696446298%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-icon-chevron-up {
  --icon-width: 25;
  --icon-height: 24;
}
.icon-clipper {
  --icon-width: 25;
  --icon-height: 24;
  --relative-pos: 0 1.9058963668850506%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-icon-clipper {
  --icon-width: 25;
  --icon-height: 24;
}
.icon-doodle-arrow-bow-right {
  --icon-width: 200;
  --icon-height: 53.98;
  --relative-pos: 0 2.068825583415445%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-icon-doodle-arrow-bow-right {
  --icon-width: 200;
  --icon-height: 53.98;
}
.icon-doodle-arrow-corner-right {
  --icon-width: 200;
  --icon-height: 83.42;
  --relative-pos: 0 2.431638406067669%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-icon-doodle-arrow-corner-right {
  --icon-width: 200;
  --icon-height: 83.42;
}
.icon-doodle-arrow-right {
  --icon-width: 185.1;
  --icon-height: 73.6;
  --relative-pos: 0 2.987770061216089%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-icon-doodle-arrow-right {
  --icon-width: 185.1;
  --icon-height: 73.6;
}
.icon-doodle-beker {
  --icon-width: 151.7;
  --icon-height: 160.9;
  --relative-pos: 0 3.499375588516171%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-icon-doodle-beker {
  --icon-width: 151.7;
  --icon-height: 160.9;
}
.icon-doodle-berg {
  --icon-width: 200;
  --icon-height: 168.15;
  --relative-pos: 0 4.576781353457808%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-icon-doodle-berg {
  --icon-width: 200;
  --icon-height: 168.15;
}
.icon-doodle-blikem {
  --icon-width: 124.42;
  --icon-height: 200;
  --relative-pos: 0 5.718111817877469%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-icon-doodle-blikem {
  --icon-width: 124.42;
  --icon-height: 200;
}
.icon-doodle-boog {
  --icon-width: 200;
  --icon-height: 115.37;
  --relative-pos: 0 7.017483120423074%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-icon-doodle-boog {
  --icon-width: 200;
  --icon-height: 115.37;
}
.icon-doodle-bureaulamp {
  --icon-width: 188.8;
  --icon-height: 144.2;
  --relative-pos: 0 7.804786935987406%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-icon-doodle-bureaulamp {
  --icon-width: 188.8;
  --icon-height: 144.2;
}
.icon-doodle-cadeeau {
  --icon-width: 173.26;
  --icon-height: 200;
  --relative-pos: 0 8.804820890525612%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-icon-doodle-cadeeau {
  --icon-width: 173.26;
  --icon-height: 200;
}
.icon-doodle-cirkel-met-pijlen {
  --icon-width: 198;
  --icon-height: 191.1;
  --relative-pos: 0 10.137915805111115%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-icon-doodle-cirkel-met-pijlen {
  --icon-width: 198;
  --icon-height: 191.1;
}
.icon-doodle-confetti {
  --icon-width: 200;
  --icon-height: 177.12;
  --relative-pos: 0 11.412045022422964%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-icon-doodle-confetti {
  --icon-width: 200;
  --icon-height: 177.12;
}
.icon-doodle-consoler {
  --icon-width: 200;
  --icon-height: 182.65;
  --relative-pos: 0 12.606713994790116%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-icon-doodle-consoler {
  --icon-width: 200;
  --icon-height: 182.65;
}
.icon-doodle-denkwolk {
  --icon-width: 177.7;
  --icon-height: 177.9;
  --relative-pos: 0 13.826209626197592%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-icon-doodle-denkwolk {
  --icon-width: 177.7;
  --icon-height: 177.9;
}
.icon-doodle-diamant-baluw {
  --icon-width: 200;
  --icon-height: 191.24;
  --relative-pos: 0 15.02968463090949%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-icon-doodle-diamant-baluw {
  --icon-width: 200;
  --icon-height: 191.24;
}
.icon-doodle-dobbelsteen {
  --icon-width: 166.3;
  --icon-height: 163.3;
  --relative-pos: 0 16.28405591883353%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-icon-doodle-dobbelsteen {
  --icon-width: 166.3;
  --icon-height: 163.3;
}
.icon-doodle-dollarteken {
  --icon-width: 110.2;
  --icon-height: 186.8;
  --relative-pos: 0 17.406778073614213%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-icon-doodle-dollarteken {
  --icon-width: 110.2;
  --icon-height: 186.8;
}
.icon-doodle-e-mail {
  --icon-width: 200;
  --icon-height: 173.66;
  --relative-pos: 0 18.64137837920935%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-icon-doodle-e-mail {
  --icon-width: 200;
  --icon-height: 173.66;
}
.icon-doodle-envelop {
  --icon-width: 181.2;
  --icon-height: 122.2;
  --relative-pos: 0 19.736491527230097%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-icon-doodle-envelop {
  --icon-width: 181.2;
  --icon-height: 122.2;
}
.icon-doodle-facebook-logo {
  --icon-width: 185.87;
  --icon-height: 200;
  --relative-pos: 0 20.66287244727151%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-icon-doodle-facebook-logo {
  --icon-width: 185.87;
  --icon-height: 200;
}
.icon-doodle-hanglamp {
  --icon-width: 81.84;
  --icon-height: 200;
  --relative-pos: 0 22.00200870438567%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-icon-doodle-hanglamp {
  --icon-width: 81.84;
  --icon-height: 200;
}
.icon-doodle-hartje {
  --icon-width: 142.2;
  --icon-height: 158.5;
  --relative-pos: 0 23.276466464127132%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-icon-doodle-hartje {
  --icon-width: 142.2;
  --icon-height: 158.5;
}
.icon-doodle-instagram-logo {
  --icon-width: 200;
  --icon-height: 176.57;
  --relative-pos: 0 24.3675305496633%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-icon-doodle-instagram-logo {
  --icon-width: 200;
  --icon-height: 176.57;
}
.icon-doodle-koffie {
  --icon-width: 200;
  --icon-height: 181.37;
  --relative-pos: 0 25.559011423982003%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-icon-doodle-koffie {
  --icon-width: 200;
  --icon-height: 181.37;
}
.icon-doodle-krabbel {
  --icon-width: 200;
  --icon-height: 108.1;
  --relative-pos: 0 26.64554898215866%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-icon-doodle-krabbel {
  --icon-width: 200;
  --icon-height: 108.1;
}
.icon-doodle-krabbel-2 {
  --icon-width: 200;
  --icon-height: 72.3;
  --relative-pos: 0 27.30586149893445%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-icon-doodle-krabbel-2 {
  --icon-width: 200;
  --icon-height: 72.3;
}
.icon-doodle-krabbel-3 {
  --icon-width: 200;
  --icon-height: 129.93;
  --relative-pos: 0 27.897237400425322%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-icon-doodle-krabbel-3 {
  --icon-width: 200;
  --icon-height: 129.93;
}
.icon-doodle-krabbel-3-2 {
  --icon-width: 200;
  --icon-height: 129.93;
  --relative-pos: 0 28.763611232736668%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-icon-doodle-krabbel-3-2 {
  --icon-width: 200;
  --icon-height: 129.93;
}
.icon-doodle-krabbel-4 {
  --icon-width: 169.2;
  --icon-height: 182.7;
  --relative-pos: 0 29.734555887722962%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-icon-doodle-krabbel-4 {
  --icon-width: 169.2;
  --icon-height: 182.7;
}
.icon-doodle-krabbel-5 {
  --icon-width: 162.7;
  --icon-height: 175.7;
  --relative-pos: 0 30.943961281610772%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-icon-doodle-krabbel-5 {
  --icon-width: 162.7;
  --icon-height: 175.7;
}
.icon-doodle-krabbel-6 {
  --icon-width: 158.9;
  --icon-height: 192.5;
  --relative-pos: 0 32.15660030115443%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-icon-doodle-krabbel-6 {
  --icon-width: 158.9;
  --icon-height: 192.5;
}
.icon-doodle-kruis {
  --icon-width: 178;
  --icon-height: 197.8;
  --relative-pos: 0 33.46008622767319%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-icon-doodle-kruis {
  --icon-width: 178;
  --icon-height: 197.8;
}
.icon-doodle-krul {
  --icon-width: 200;
  --icon-height: 165.02;
  --relative-pos: 0 34.70946520970636%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-icon-doodle-krul {
  --icon-width: 200;
  --icon-height: 165.02;
}
.icon-doodle-krul-1 {
  --icon-width: 200;
  --icon-height: 100.46;
  --relative-pos: 0 35.66454311206063%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-icon-doodle-krul-1 {
  --icon-width: 200;
  --icon-height: 100.46;
}
.icon-doodle-lamp {
  --icon-width: 186.68;
  --icon-height: 200;
  --relative-pos: 0 36.578506863073315%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-icon-doodle-lamp {
  --icon-width: 186.68;
  --icon-height: 200;
}
.icon-doodle-lange-krabbel {
  --icon-width: 200;
  --icon-height: 40.26;
  --relative-pos: 0 37.516379878023734%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-icon-doodle-lange-krabbel {
  --icon-width: 200;
  --icon-height: 40.26;
}
.icon-doodle-langgerekte-krul {
  --icon-width: 200;
  --icon-height: 90.26;
  --relative-pos: 0 37.91358308618162%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-icon-doodle-langgerekte-krul {
  --icon-width: 200;
  --icon-height: 90.26;
}
.icon-doodle-lijn-1 {
  --icon-width: 100.26;
  --icon-height: 200;
  --relative-pos: 0 38.80147304988282%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-icon-doodle-lijn-1 {
  --icon-width: 100.26;
  --icon-height: 200;
}
.icon-doodle-lijn-2 {
  --icon-width: 200;
  --icon-height: 114;
  --relative-pos: 0 39.91079155848479%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-icon-doodle-lijn-2 {
  --icon-width: 200;
  --icon-height: 114;
}
.icon-doodle-lijn-3 {
  --icon-width: 200;
  --icon-height: 88.33;
  --relative-pos: 0 40.6003454584968%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-icon-doodle-lijn-3 {
  --icon-width: 200;
  --icon-height: 88.33;
}
.icon-doodle-lijn-4 {
  --icon-width: 200;
  --icon-height: 52.71;
  --relative-pos: 0 41.09455526978993%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-icon-doodle-lijn-4 {
  --icon-width: 200;
  --icon-height: 52.71;
}
.icon-doodle-lijn-5 {
  --icon-width: 200;
  --icon-height: 37.01;
  --relative-pos: 0 41.40286223530417%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-icon-doodle-lijn-5 {
  --icon-width: 200;
  --icon-height: 37.01;
}
.icon-doodle-lijn-omlaag {
  --icon-width: 98.94;
  --icon-height: 200;
  --relative-pos: 0 42.109139604954805%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-icon-doodle-lijn-omlaag {
  --icon-width: 98.94;
  --icon-height: 200;
}
.icon-doodle-lijn-rechts {
  --icon-width: 200;
  --icon-height: 79.69;
  --relative-pos: 0 43.1010719805836%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-icon-doodle-lijn-rechts {
  --icon-width: 200;
  --icon-height: 79.69;
}
.icon-doodle-linkedin-logo {
  --icon-width: 200;
  --icon-height: 166.33;
  --relative-pos: 0 43.884994458425496%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-icon-doodle-linkedin-logo {
  --icon-width: 200;
  --icon-height: 166.33;
}
.icon-doodle-megafoon {
  --icon-width: 187;
  --icon-height: 152.5;
  --relative-pos: 0 44.959118972134156%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-icon-doodle-megafoon {
  --icon-width: 187;
  --icon-height: 152.5;
}
.icon-doodle-opencirkel {
  --icon-width: 200;
  --icon-height: 129.57;
  --relative-pos: 0 45.910047229569564%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-icon-doodle-opencirkel {
  --icon-width: 200;
  --icon-height: 129.57;
}
.icon-doodle-opencirkel-2 {
  --icon-width: 200;
  --icon-height: 148.65;
  --relative-pos: 0 46.83595405152022%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-icon-doodle-opencirkel-2 {
  --icon-width: 200;
  --icon-height: 148.65;
}
.icon-doodle-pauze-button {
  --icon-width: 200;
  --icon-height: 197.05;
  --relative-pos: 0 47.98516530045956%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-icon-doodle-pauze-button {
  --icon-width: 200;
  --icon-height: 197.05;
}
.icon-doodle-pijl-benedenwijzend {
  --icon-width: 200;
  --icon-height: 122.65;
  --relative-pos: 0 49.066268772044346%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-icon-doodle-pijl-benedenwijzend {
  --icon-width: 200;
  --icon-height: 122.65;
}
.icon-doodle-pijl-en-boog {
  --icon-width: 200;
  --icon-height: 140.85;
  --relative-pos: 0 49.94614566347542%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-icon-doodle-pijl-en-boog {
  --icon-width: 200;
  --icon-height: 140.85;
}
.icon-doodle-pijl-horizontaal-rechts {
  --icon-width: 200;
  --icon-height: 98.08;
  --relative-pos: 0 50.74177424632172%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-icon-doodle-pijl-horizontaal-rechts {
  --icon-width: 200;
  --icon-height: 98.08;
}
.icon-doodle-pijl-links-boog {
  --icon-width: 200;
  --icon-height: 80.83;
  --relative-pos: 0 51.34125627650013%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-icon-doodle-pijl-links-boog {
  --icon-width: 200;
  --icon-height: 80.83;
}
.icon-doodle-pijl-linksnaarbeneden {
  --icon-width: 200;
  --icon-height: 129.1;
  --relative-pos: 0 52.046195163235794%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-icon-doodle-pijl-linksnaarbeneden {
  --icon-width: 200;
  --icon-height: 129.1;
}
.icon-doodle-pijl-linksonder {
  --icon-width: 200;
  --icon-height: 144.69;
  --relative-pos: 0 52.96755037087292%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-icon-doodle-pijl-linksonder {
  --icon-width: 200;
  --icon-height: 144.69;
}
.icon-doodle-pijl-linkswijzend {
  --icon-width: 200;
  --icon-height: 68.7;
  --relative-pos: 0 53.662810378128675%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-icon-doodle-pijl-linkswijzend {
  --icon-width: 200;
  --icon-height: 68.7;
}
.icon-doodle-pijl-rechts-boog {
  --icon-width: 200;
  --icon-height: 73.21;
  --relative-pos: 0 54.13699168558318%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-icon-doodle-pijl-rechts-boog {
  --icon-width: 200;
  --icon-height: 73.21;
}
.icon-doodle-pijl-rechts-boogjes {
  --icon-width: 200;
  --icon-height: 58.86;
  --relative-pos: 0 54.57630401415747%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-icon-doodle-pijl-rechts-boogjes {
  --icon-width: 200;
  --icon-height: 58.86;
}
.icon-doodle-pijl-rechtswijzend {
  --icon-width: 200;
  --icon-height: 121.75;
  --relative-pos: 0 55.19790851414584%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-icon-doodle-pijl-rechtswijzend {
  --icon-width: 200;
  --icon-height: 121.75;
}
.icon-doodle-pijl-schijnnaaronder {
  --icon-width: 145.21;
  --icon-height: 200;
  --relative-pos: 0 56.303983930364915%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-icon-doodle-pijl-schijnnaaronder {
  --icon-width: 145.21;
  --icon-height: 200;
}
.icon-doodle-pijl-vanonder-rechts {
  --icon-width: 200;
  --icon-height: 113.41;
  --relative-pos: 0 57.31084392531017%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-icon-doodle-pijl-vanonder-rechts {
  --icon-width: 200;
  --icon-height: 113.41;
}
.icon-doodle-play-button {
  --icon-width: 156;
  --icon-height: 157;
  --relative-pos: 0 58.23875016691147%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-icon-doodle-play-button {
  --icon-width: 156;
  --icon-height: 157;
}
.icon-doodle-praatwolk-1 {
  --icon-width: 151.9;
  --icon-height: 148.7;
  --relative-pos: 0 59.25411876180245%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-icon-doodle-praatwolk-1 {
  --icon-width: 151.9;
  --icon-height: 148.7;
}
.icon-doodle-praatwolk-2 {
  --icon-width: 189.3;
  --icon-height: 116.6;
  --relative-pos: 0 60.119586640387794%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-icon-doodle-praatwolk-2 {
  --icon-width: 189.3;
  --icon-height: 116.6;
}
.icon-doodle-praatwolk-methart {
  --icon-width: 137.5;
  --icon-height: 166.1;
  --relative-pos: 0 61.10001402908698%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-icon-doodle-praatwolk-methart {
  --icon-width: 137.5;
  --icon-height: 166.1;
}
.icon-doodle-praatwolkje {
  --icon-width: 200;
  --icon-height: 196.09;
  --relative-pos: 0 62.34055898321899%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-icon-doodle-praatwolkje {
  --icon-width: 200;
  --icon-height: 196.09;
}
.icon-doodle-punaise {
  --icon-width: 147.7;
  --icon-height: 189.2;
  --relative-pos: 0 63.62991609682988%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-icon-doodle-punaise {
  --icon-width: 147.7;
  --icon-height: 189.2;
}
.icon-doodle-puzzelstuk {
  --icon-width: 158.9;
  --icon-height: 159.8;
  --relative-pos: 0 64.77375928201292%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-icon-doodle-puzzelstuk {
  --icon-width: 158.9;
  --icon-height: 159.8;
}
.icon-doodle-rondje {
  --icon-width: 200;
  --icon-height: 171.75;
  --relative-pos: 0 65.89477553339015%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-icon-doodle-rondje {
  --icon-width: 200;
  --icon-height: 171.75;
}
.icon-doodle-slingers {
  --icon-width: 200;
  --icon-height: 119.38;
  --relative-pos: 0 66.81042807423204%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-icon-doodle-slingers {
  --icon-width: 200;
  --icon-height: 119.38;
}
.icon-doodle-smiley {
  --icon-width: 194.14;
  --icon-height: 200;
  --relative-pos: 0 67.97455641111483%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-icon-doodle-smiley {
  --icon-width: 194.14;
  --icon-height: 200;
}
.icon-doodle-smiley-1 {
  --icon-width: 169;
  --icon-height: 177.8;
  --relative-pos: 0 69.21081485839595%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-icon-doodle-smiley-1 {
  --icon-width: 169;
  --icon-height: 177.8;
}
.icon-doodle-snapchat-logo {
  --icon-width: 200;
  --icon-height: 173.81;
  --relative-pos: 0 70.3821019584672%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-icon-doodle-snapchat-logo {
  --icon-width: 200;
  --icon-height: 173.81;
}
.icon-doodle-speelveld {
  --icon-width: 138.14;
  --icon-height: 200;
  --relative-pos: 0 71.67057248074991%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-icon-doodle-speelveld {
  --icon-width: 138.14;
  --icon-height: 200;
}
.icon-doodle-ster {
  --icon-width: 200;
  --icon-height: 163.56;
  --relative-pos: 0 72.83200547175154%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-icon-doodle-ster {
  --icon-width: 200;
  --icon-height: 163.56;
}
.icon-doodle-ster2 {
  --icon-width: 200;
  --icon-height: 166.89;
  --relative-pos: 0 73.9438713371294%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-icon-doodle-ster2 {
  --icon-width: 200;
  --icon-height: 166.89;
}
.icon-doodle-stip {
  --icon-width: 122.4;
  --icon-height: 109.6;
  --relative-pos: 0 74.77338373687223%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-icon-doodle-stip {
  --icon-width: 122.4;
  --icon-height: 109.6;
}
.icon-doodle-stip-2 {
  --icon-width: 108.5;
  --icon-height: 114.7;
  --relative-pos: 0 75.53111455829777%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-icon-doodle-stip-2 {
  --icon-width: 108.5;
  --icon-height: 114.7;
}
.icon-doodle-stip-3 {
  --icon-width: 108.5;
  --icon-height: 106.1;
  --relative-pos: 0 76.25308572150989%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-icon-doodle-stip-3 {
  --icon-width: 108.5;
  --icon-height: 106.1;
}
.icon-doodle-stip-4 {
  --icon-width: 135.1;
  --icon-height: 113.5;
  --relative-pos: 0 77.00296242053058%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-icon-doodle-stip-4 {
  --icon-width: 135.1;
  --icon-height: 113.5;
}
.icon-doodle-swirl {
  --icon-width: 200;
  --icon-height: 70.46;
  --relative-pos: 0 77.5397058257338%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-icon-doodle-swirl {
  --icon-width: 200;
  --icon-height: 70.46;
}
.icon-doodle-swirl-2 {
  --icon-width: 200;
  --icon-height: 193.82;
  --relative-pos: 0 78.65509953029145%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-icon-doodle-swirl-2 {
  --icon-width: 200;
  --icon-height: 193.82;
}
.icon-doodle-telefoon {
  --icon-width: 128.73;
  --icon-height: 200;
  --relative-pos: 0 79.98660863742886%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-icon-doodle-telefoon {
  --icon-width: 128.73;
  --icon-height: 200;
}
.icon-doodle-tiktok-logo {
  --icon-width: 200;
  --icon-height: 196.33;
  --relative-pos: 0 81.30576550656785%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-icon-doodle-tiktok-logo {
  --icon-width: 200;
  --icon-height: 196.33;
}
.icon-doodle-uitroepteken2 {
  --icon-width: 59.68;
  --icon-height: 200;
  --relative-pos: 0 82.64479410780046%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-icon-doodle-uitroepteken2 {
  --icon-width: 59.68;
  --icon-height: 200;
}
.icon-doodle-verzenden-logo {
  --icon-width: 200;
  --icon-height: 160.27;
  --relative-pos: 0 83.76110954922059%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-icon-doodle-verzenden-logo {
  --icon-width: 200;
  --icon-height: 160.27;
}
.icon-doodle-vierkant {
  --icon-width: 200;
  --icon-height: 169.28;
  --relative-pos: 0 84.88732917627752%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-icon-doodle-vierkant {
  --icon-width: 200;
  --icon-height: 169.28;
}
.icon-doodle-vierkant2 {
  --icon-width: 200;
  --icon-height: 168.59;
  --relative-pos: 0 86.01929253575173%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-icon-doodle-vierkant2 {
  --icon-width: 200;
  --icon-height: 168.59;
}
.icon-doodle-vinkje {
  --icon-width: 161;
  --icon-height: 192.6;
  --relative-pos: 0 87.28852125495236%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-icon-doodle-vinkje {
  --icon-width: 161;
  --icon-height: 192.6;
}
.icon-doodle-voetbal {
  --icon-width: 176.6;
  --icon-height: 153.4;
  --relative-pos: 0 88.34837400544667%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-icon-doodle-voetbal {
  --icon-width: 176.6;
  --icon-height: 153.4;
}
.icon-doodle-vraagteken-2 {
  --icon-width: 98.68;
  --icon-height: 200;
  --relative-pos: 0 89.65517241379311%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-icon-doodle-vraagteken-2 {
  --icon-width: 98.68;
  --icon-height: 200;
}
.icon-doodle-wolk-met-vraagteken {
  --icon-width: 200;
  --icon-height: 163.23;
  --relative-pos: 0 90.77083070338377%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-icon-doodle-wolk-met-vraagteken {
  --icon-width: 200;
  --icon-height: 163.23;
}
.icon-doodle-youtube-logo {
  --icon-width: 197.15;
  --icon-height: 200;
  --relative-pos: 0 92.09240040174087%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-icon-doodle-youtube-logo {
  --icon-width: 197.15;
  --icon-height: 200;
}
.icon-doodle-youtube-logo-orange {
  --icon-width: 197.15;
  --icon-height: 200;
  --relative-pos: 0 93.43153665885504%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-icon-doodle-youtube-logo-orange {
  --icon-width: 197.15;
  --icon-height: 200;
}
.icon-external-link {
  --icon-width: 25;
  --icon-height: 24;
  --relative-pos: 0 93.66686519753821%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-icon-external-link {
  --icon-width: 25;
  --icon-height: 24;
}
.icon-facebook {
  --icon-width: 25;
  --icon-height: 24;
  --relative-pos: 0 93.82568989477863%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-icon-facebook {
  --icon-width: 25;
  --icon-height: 24;
}
.icon-instagram {
  --icon-width: 25;
  --icon-height: 24;
  --relative-pos: 0 93.98451459201905%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-icon-instagram {
  --icon-width: 25;
  --icon-height: 24;
}
.icon-linkedin {
  --icon-width: 25;
  --icon-height: 24;
  --relative-pos: 0 94.14333928925949%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-icon-linkedin {
  --icon-width: 25;
  --icon-height: 24;
}
.icon-loading {
  --icon-width: 50;
  --icon-height: 50;
  --relative-pos: 0 94.4647000331455%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-icon-loading {
  --icon-width: 50;
  --icon-height: 50;
}
.icon-mail {
  --icon-width: 25;
  --icon-height: 24;
  --relative-pos: 0 94.63304877241744%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-icon-mail {
  --icon-width: 25;
  --icon-height: 24;
}
.icon-pause {
  --icon-width: 50;
  --icon-height: 50;
  --relative-pos: 0 94.9552535631422%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-icon-pause {
  --icon-width: 50;
  --icon-height: 50;
}
.icon-play {
  --icon-width: 156;
  --icon-height: 157;
  --relative-pos: 0 95.96741888102551%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-icon-play {
  --icon-width: 156;
  --icon-height: 157;
}
.icon-thumbtack {
  --icon-width: 100;
  --icon-height: 100;
  --relative-pos: 0 96.64782174925175%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-icon-thumbtack {
  --icon-width: 100;
  --icon-height: 100;
}
.icon-twitter {
  --icon-width: 25;
  --icon-height: 24;
  --relative-pos: 0 96.82350605519159%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-icon-twitter {
  --icon-width: 25;
  --icon-height: 24;
}
.icon-x {
  --icon-width: 25;
  --icon-height: 24;
  --relative-pos: 0 96.982330752432%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-icon-x {
  --icon-width: 25;
  --icon-height: 24;
}
.icon-x-octagon {
  --icon-width: 25;
  --icon-height: 24;
  --relative-pos: 0 97.14115544967242%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-icon-x-octagon {
  --icon-width: 25;
  --icon-height: 24;
}
.line-icon-alert-circle {
  --icon-width: 25;
  --icon-height: 24;
  --relative-pos: 0 97.29998014691284%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-line-icon-alert-circle {
  --icon-width: 25;
  --icon-height: 24;
}
.line-icon-alert-octagon {
  --icon-width: 25;
  --icon-height: 24;
  --relative-pos: 0 97.45880484415326%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-line-icon-alert-octagon {
  --icon-width: 25;
  --icon-height: 24;
}
.line-icon-alert-triangle {
  --icon-width: 25;
  --icon-height: 24;
  --relative-pos: 0 97.61762954139368%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-line-icon-alert-triangle {
  --icon-width: 25;
  --icon-height: 24;
}
.line-icon-arrow-down {
  --icon-width: 25;
  --icon-height: 24;
  --relative-pos: 0 97.7764542386341%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-line-icon-arrow-down {
  --icon-width: 25;
  --icon-height: 24;
}
.line-icon-arrow-left {
  --icon-width: 25;
  --icon-height: 24;
  --relative-pos: 0 97.93527893587454%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-line-icon-arrow-left {
  --icon-width: 25;
  --icon-height: 24;
}
.line-icon-arrow-right {
  --icon-width: 25;
  --icon-height: 24;
  --relative-pos: 0 98.09410363311495%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-line-icon-arrow-right {
  --icon-width: 25;
  --icon-height: 24;
}
.line-icon-arrow-up {
  --icon-width: 25;
  --icon-height: 24;
  --relative-pos: 0 98.25292833035537%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-line-icon-arrow-up {
  --icon-width: 25;
  --icon-height: 24;
}
.line-icon-check {
  --icon-width: 24;
  --icon-height: 24;
  --relative-pos: 0 98.4117530275958%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-line-icon-check {
  --icon-width: 24;
  --icon-height: 24;
}
.line-icon-chevron-down {
  --icon-width: 25;
  --icon-height: 24;
  --relative-pos: 0 98.57057772483621%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-line-icon-chevron-down {
  --icon-width: 25;
  --icon-height: 24;
}
.line-icon-chevron-left {
  --icon-width: 25;
  --icon-height: 24;
  --relative-pos: 0 98.72940242207663%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-line-icon-chevron-left {
  --icon-width: 25;
  --icon-height: 24;
}
.line-icon-chevron-right {
  --icon-width: 25;
  --icon-height: 24;
  --relative-pos: 0 98.88822711931705%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-line-icon-chevron-right {
  --icon-width: 25;
  --icon-height: 24;
}
.line-icon-chevron-up {
  --icon-width: 25;
  --icon-height: 24;
  --relative-pos: 0 99.04705181655747%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-line-icon-chevron-up {
  --icon-width: 25;
  --icon-height: 24;
}
.line-icon-external-link {
  --icon-width: 25;
  --icon-height: 24;
  --relative-pos: 0 99.20587651379789%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-line-icon-external-link {
  --icon-width: 25;
  --icon-height: 24;
}
.line-icon-link {
  --icon-width: 25;
  --icon-height: 24;
  --relative-pos: 0 99.36470121103832%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-line-icon-link {
  --icon-width: 25;
  --icon-height: 24;
}
.line-icon-mail {
  --icon-width: 25;
  --icon-height: 24;
  --relative-pos: 0 99.52352590827874%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-line-icon-mail {
  --icon-width: 25;
  --icon-height: 24;
}
.line-icon-phone {
  --icon-width: 25;
  --icon-height: 24;
  --relative-pos: 0 99.68235060551916%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-line-icon-phone {
  --icon-width: 25;
  --icon-height: 24;
}
.line-icon-x {
  --icon-width: 25;
  --icon-height: 24;
  --relative-pos: 0 99.84117530275958%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-line-icon-x {
  --icon-width: 25;
  --icon-height: 24;
}
.line-icon-x-octagon {
  --icon-width: 25;
  --icon-height: 24;
  --relative-pos: 0 100%;

  @extend %svg-common;
  background-position: var(--relative-pos);
  width: calc(var(--icon-width) * 1px);
  height: calc(var(--icon-height) * 1px);
}
.aspect-ratio-line-icon-x-octagon {
  --icon-width: 25;
  --icon-height: 24;
}
