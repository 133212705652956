body {
  @each $name, $color in $theme-colors {
    @include defineColorHSL(--bs-#{$name}, hue($color), saturation($color), lightness($color));
  }
  @each $name, $color in $colors {
    @include defineColorHSL(--bs-#{$name}, hue($color), saturation($color), lightness($color));
  }
  @each $name, $color in $grays {
    @include defineColorHSL(--bs-gray-#{$name}, hue($color), saturation($color), lightness($color));
  }

  // Use `inspect` for lists so that quoted items keep the quotes.
  // See https://github.com/sass/sass/issues/2383#issuecomment-336349172
  --#{$variable-prefix}font-sans-serif: #{inspect($font-family-sans-serif)};
  --#{$variable-prefix}font-monospace: #{inspect($font-family-monospace)};
  --#{$variable-prefix}gradient: #{$gradient};
  --#{$variable-prefix}gutter-x: #{$grid-gutter-width};

  // --primary-gradient: linear-gradient(180deg, theme-color(primary) 0%, theme-color(primary-600) 100%);
  // --secondary-gradient: linear-gradient(180deg, theme-color(secondary) 0%, theme-color(secondary-600) 100%);

  // Test tints primary
  @for $i from 1 through 9 {
    @if $i < 5 {
      --bs-primary-#{$i * 100}: #{tint-color(theme-color(primary), (100% - (20% * $i)))};
    }
    @if $i == 5 {
      --bs-primary-500: #{theme-color(primary)};
    }
    @if $i > 5 {
      --bs-primary-#{$i * 100}: #{shade-color(theme-color(primary), (20% * ($i - 5)))};
    }
  }
}
