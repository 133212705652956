.block-play-cta {
  background-color: var(--bs-dark);
  @include media-breakpoint-down(sm) {
    padding: 55px 0;
  }
  &__content {
    @include media-breakpoint-down(sm) {
      width: 65%;
    }

    &__title {
      font-size: 12vw;
      text-transform: uppercase;
      position: relative;

      @include media-breakpoint-down(sm) {
        font-size: 16vw;
      }
    }

    .splitting--letter-in {
      transition-duration: 0.8s;
    }
  }
  &__icon {
    fill: var(--bs-primary);
    @include size(150px);
    top: 0.35em;
    left: 0.4em;
    min-width: unset;
    min-height: unset;

    position: absolute;
    right: 0;
    @include media-breakpoint-down(sm) {
      @include size(4rem);
    }
  }

  &__squiggle {
    position: absolute;
    max-width: 50vw;
    fill: var(--bs-secondary);
    width: 100%;
    bottom: 45%;
    left: calc(var(--container-padding-x) * -1);
  }
  &__image {
    @include media-breakpoint-down(sm) {
      width: 60%;
      position: absolute;
      right: 0;
    }
  }
}
