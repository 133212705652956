.show-case-button {
  @extend .font-display;

  @include size(100px);
  @include abs(2rem, null, null, 2rem);

  pointer-events: none;
  transition: 0.2s;
  color: var(--bs-light);
  background: $primary-gradient;
  border-radius: 50%;
  font-size: 1.5rem;
  font-weight: 600;
  font-variation-settings: 'wght' 600;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;

  @include media-breakpoint-down(md) {
    opacity: 1;
    left: 1rem;
    top: 1rem;

    @include size(75px);
    font-size: 1.2rem;
  }
}
