// PLAY / Dark theme
// See config/color dark theme section variables for base configuration

[data-bs-theme='dark'] {
  @for $i from 1 through 3 {
    h#{$i},
    .h#{$i} {
      font-family: $font-family-serif;
      font-weight: 700;
      font-variation-settings: 'wght' 550;
      line-height: 0.9;
    }
  }
}
