.block-cta {
  position: relative;
  background-color: var(--bs-yellow-100);
  z-index: 0;
  padding: 9rem 0;

  @include media-breakpoint-down(md) {
    padding: 5rem 0;
  }

  &.is--dark {
    background-color: var(--bs-dark);

    &::after {
      background-color: var(--bs-dark);
      background-image: linear-gradient(270deg, alpha(var(--bs-dark), 0.8) 0%, alpha(var(--bs-dark), 0.3) 100%),
        radial-gradient(66% 251% at 92% 45%, var(--bs-dark) 0%, transparent 58.79%), url('/assets/gfx/halftone-orange.png');
    }
  }

  &::after {
    content: '';
    background: var(--bs-yellow-100);
    @include abs(0, 0, 0, 0);
    background-image: linear-gradient(270deg, alpha(var(--bs-yellow-100), 0.8) 0%, alpha(var(--bs-yellow-100), 0.3) 100%),
      radial-gradient(66% 251% at 92% 45%, var(--bs-yellow-100) 0%, transparent 58.79%), url('/assets/gfx/halftone-orange.png');
    background-repeat: no-repeat;
    z-index: -1;
    background-size: cover;
    width: 50%;
  }
}
