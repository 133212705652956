.has--skew-bg {
  position: relative;
  z-index: 1;

  &::before {
    --skew-bg-color: var(--bs-primary);
    --rotation: 4deg;
    content: '';
    width: calc(100% - 50px);
    height: 250px;
    position: absolute;
    z-index: -1;
    right: 5px;
    top: -10px;
    transform: rotate(var(--rotation));
    background-color: var(--skew-bg-color);
    background-image: url('/assets/gfx/halftone-white.png');

    @include media-breakpoint-down(md) {
      height: 100px;
      --rotation: 8deg;
    }
  }

  &.skew-bg--secondary {
    &::before {
      --skew-bg-color: var(--bs-secondary);
      background-image: url('/assets/gfx/halftone-white.png');
    }
  }
}
