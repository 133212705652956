.footer {
  position: relative;

  &.big-text-spacer-top {
    margin-top: var(--big-text-font-size);
  }

  &__title {
    @include abs(0, null, null, 50%);
    transform: translate(-50%, -61%);
    overflow: hidden;
    width: 100%;
  }

  &__contact {
    @include media-breakpoint-down(md) {
      text-align: center;
    }
  }
}

.footer__payoff .row {
  @include media-breakpoint-down(md) {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3ex;
    text-align: center;
  }
}

.footer__payoff__text {
  // font-family: 'stinger-variable', sans-serif;
  font-weight: 700;
  font-variation-settings: 'wght' 550;
  line-height: 0.9;

  @include media-breakpoint-down(md) {
    max-width: 80vw;
    text-align: center;
    margin: auto;
  }
}

.footer__nav__list {
  gap: 0 1rem;

  @include media-breakpoint-down(md) {
    flex-direction: column;
    align-items: center;
  }

  &.type--dots {
    gap: 0 2rem;

    .footer__nav__list__item {
      position: relative;

      &::before {
        @include size(8px);
        @include abs(50%, null, null, -1rem);
        content: '';
        display: inline-block;
        background-color: var(--bs-primary);
        border-radius: 50%;
        transform: translate(-50%, -50%);

        @include media-breakpoint-down(md) {
          display: none;
        }
      }

      &:first-child::before {
        display: none;
      }
    }
  }
}
