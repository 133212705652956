.block-default {
  background-color: var(--bs-tertiary);
  position: relative;
  //margin-top: var(--clip-height);

  --clip-height: clamp(50px, 6vh, 200px);

  &:before {
    background-color: var(--bs-tertiary);
    clip-path: polygon(100% 0%, 100% 100%, 0 100%, 0 100%);
    content: '';
    position: absolute;
    width: 100%;
    height: var(--clip-height);
    transform: translate(0, -99.5%);
    z-index: -2;
  }

  &__item {
    align-items: center;
    justify-content: space-between;
    z-index: 1;
    position: relative;
    //&:not(:last-child) {
    //  margin-bottom: 7rem;
    //}

    &:last-child {
      position: relative;
      //padding-bottom: 7rem;
    }

    &__figure {
      @include media-breakpoint-down(md) {
        overflow: hidden;
      }

      .background-dots {
        bottom: -20px;
      }

      img {
        max-height: 80vh;
        object-fit: contain;
      }
    }

    &__content {
      display: flex;
      flex-flow: column;
      gap: 1.5rem;

      @include media-breakpoint-down(md) {
        gap: 1rem;
      }
    }
    &__title {
      margin-bottom: 0;
      //text-wrap: wrap;
      //hyphens: manual;
    }
  }
  &__payoff {
    .big-text {
      position: absolute !important;
      bottom: 0;
      z-index: 0;
      height: clamp(30%, 60%, 90%);
      @include media-breakpoint-down(md) {
        height: auto;
        top: 50%;
      }

      .splitting .word {
        --a: var(--word-index);
        --b: 2;
        --floor: calc(var(--a) / var(--b) - 0.5);
        --mod: calc(var(--a) - var(--b) * var(--floor));
        margin-left: calc(var(--mod) * 1px);
      }
    }
  }
}

[data-bs-theme='dark'] .block-default {
  background-color: transparent;

  &::before {
    display: none;
  }
}

.block-default__item .background-dots {
  max-width: 500px;
  max-height: 330px;
}
